import { googleCloudKey } from '../assets/constants';

export const searchImageGoogle = (word, offet) => {
  const cx = '001707121582197719860:kbnkgydq8ly';
  const query = `key=${googleCloudKey}&cx=${cx}&q=${word}&num=10&safe=active&searchType=image&start=${offet}&filter=1`;
  const endpoint = `https://www.googleapis.com/customsearch/v1?${query}`

  return fetch(endpoint, {
      method: "GET",
      mode: "cors",
      headers: {
          "Content-Type": "application/json"
      },
  })
  .then(res => res.json())
  .catch(error => console.error('Error:', error));
}

export const getImagesGoogle = word => {
  let offset = 1;
  let images = [];
  let end = false

  const infiniteSearch = () => {
    return searchImageGoogle(word, offset).then(res => {
      const { items } = res;
      if (items && items.length) {
        images = [...images, ...items.filter(item => item.link.indexOf('https://') !== -1)];
        offset += 10;
      } else {
        end = true;
      }

      if (end || images.length > 5) {
        return images;
      } else {
        return infiniteSearch();
      }
    })
  }

  return infiniteSearch();
}
