import { connect } from 'react-redux';

import * as app from '../modules/app';
import Board from '../components/Board/Board';

const mapStateToProps = state => {
  const {
    loginUser,
  } = state.app;

  return {
    loginUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showModalRegister: () => dispatch(app.showModalRegister()),
    // showModaLogin: () => dispatch(app.showModaLogin()),
    hideModal: () => dispatch(app.hideModal()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Board);
