import { connect } from 'react-redux';

import Top from '../components/Top';

const mapStateToProps = state => {
  const {
    loginUser,
  } = state.app;

  return {
    loginUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(Top);
