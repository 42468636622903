import React from 'react';

import HeaderContainer from '../../containers/HeaderContainer';
import Footer from '../Footer';

const Terms = () => (
  <React.Fragment>
    <HeaderContainer />
    <div className="content-wrapper">
        <div className="content-insede ">
          <section className="section has-background-light">
            <div className="container">
                <div className="columns is-centered">
                  <div className="column is-four-fifths">
                    <h2 className="title has-text-centered">
                        利用規約
                    </h2>
                    <div className="card">
                          <div className="card-content">
                              <dl className="dl-list">
                                  <dt>ダミーテキスト</dt>
                            </dl>
                            <p className="is-size-7">ダミー日時 制定</p>

                          </div>
                        </div>
                      </div>
                </div>
            </div>
          </section>
        </div>
      </div>
    <Footer />
  </React.Fragment>
)

export default Terms;
