import React, { Component } from 'react';
import { withRouter } from 'react-router';

import {
  reAuth,
  deleteUser,
} from '../../lib/firestore';

class ModalLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      pass: '',
      errorMessage: null,
    };
  }

  handleEmail = event => this.setState({ email: event.target.value })

  handlePass = event => this.setState({ pass: event.target.value })

  handleDelete = async event => {
    event && event.preventDefault();
    event && event.stopPropagation();
    const { pass } = this.state;
    const res = await reAuth(pass);
    console.log(res);
    const { errorMessage } = res;
    if (!errorMessage) {
      const resDelete = await deleteUser();

      if (resDelete && resDelete.errorMessage) {
        this.setState({ errorMessage: resDelete.errorMessage });
      } else {
        this.clearState(this.openTop);
      }
    } else {
      this.setState({ errorMessage });
    }
  }

  clearState = callback => {
    this.setState({
      email: '',
      pass: '',
      invalidEmail: false,
      errorMessage: '',
    }, () => callback && callback())
  }

  handleClose = event => {
    event && event.preventDefault();
    event && event.stopPropagation();
    const { handleClose } = this.props;
    this.clearState(handleClose)
  }

  openTop = () => this.props.history.push({ pathname: `${process.env.PUBLIC_URL}/` })

  render() {
    const { pass, errorMessage } = this.state;
    const { show } = this.props;
    return (
      <div className={`modal${ show ? ' is-active' : ''}`} id="ModalLogin">
          <div className="modal-background"></div>
          <div className="modal-card">
            <section className="modal-card-body">
                <button
                  onClick={this.handleClose}
                  className="delete is-pulled-right"
                  aria-label="close"
                />
                <section className="section">
                    <h2 className="title has-text-centered">ユーザーの退会</h2>
                    <form onSubmit={this.handleDelete} >
                      <div className="field">
                          <label className="label">パスワード</label>
                          <div className="control">
                            <input className="input" type="password" value={pass} onChange={this.handlePass}/>
                          </div>
                      </div>
                      {errorMessage && <p className="help is-danger">{errorMessage}</p>}
                      <div className="field columns is-half" style={{ margin: '1rem 0' }}>
                        <div className="control column">
                          <button
                            className="button is-fullwidth is-danger is-fullwidth"
                            onClick={this.handleLogin}
                          >退会</button>
                        </div>
                        <div className="control column is-half">
                          <button
                            onClick={this.handleClose}
                            className="button is-fullwidth is-fullwidth"
                          >キャンセル</button>
                         </div>
                      </div>
                  </form>
                </section>
            </section>
          </div>
       </div>
   );
  }
}

export default withRouter(ModalLogin);
