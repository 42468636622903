import React, { Component } from 'react';
import {
  getBoardIdByAlias,
  setAlias,
} from '../../lib/firestore';

class ModalUrl extends Component {
  constructor(props) {
    super(props);
    const currentUrl = window.location.href;
    const origin = window.location.origin;

    this.state = {
      editing: false,
      currentUrl,
      origin,
      path: '/alpha/board/',
      alias: '',
      invalid: false,
      errorMessage: '無効な文字列です。',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { editing } = prevState;
    if (!editing && this.state.editing) {
      this.inputUrl && this.inputUrl.focus();
    }
  }

  handleAlias = event => this.setState({ alias: event.target.value.trim() });

  validateAlias = async () => {
    let errorMessage = '無効な文字列です。';
    const { alias } = this.state;
    if (!alias) {
      return errorMessage;
    }
    // const a = /[ -~]+/;
    const a = /[a-zA-Z\d_-]+/;
    const b = alias.match(a);
    const valid = b && b[0] === alias;
    if (!valid) {
      return errorMessage;
    }

    const boardId = await getBoardIdByAlias(alias);
    errorMessage = '文字列が他のボードと重複しています。';
    return boardId ? errorMessage : false;
  }

  handleSave = async event => {
    event && event.preventDefault();
    const errorMessage = await this.validateAlias();
    if (!errorMessage) {
      const { origin, path, alias } = this.state;
      const { boardId } = this.props;

      setAlias(boardId, alias).then(() =>{
        const currentUrl = origin + path + alias
        window.history.replaceState('', '', currentUrl);

        this.setState({
          currentUrl,
          alias: '',
          invalid: false,
        });
        this.toggleEditing();
      })
        .catch(() => this.setState({ invalid: true, errorMessage: 'データの更新に失敗しました。' }))
    } else {
      this.setState({ invalid: true, errorMessage });
    }
  }

  handleCancel = () => {
    this.setState({ alias: '', invalid: false, errorMessage: null });
    this.toggleEditing();
  }

  toggleEditing = () => this.setState({ editing: !this.state.editing });

  handleClose = () => {
    this.setState({ alias: '', invalid: false, editing: false, errorMessage: null });
    this.props.toggleModal();
  }

  copyUrl = event => {
    const { currentUrl } = this.state;

    const input = document.createElement('input');
    document.body.appendChild(input);
    input.value = currentUrl;
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
  }

  render() {
    const {
      currentUrl,
      origin,
      path,
      alias,
      editing,
      invalid,
      errorMessage,
    } = this.state;
    const { active } = this.props;

    const showURL = (
      <div className="url-show">
        <h4
          className="text-url has-text-centered"
        >
          {currentUrl}
        </h4>
        <button className="button" onClick={this.toggleEditing} ><span className="icon"><i className="fas fa-pen"></i></span><span>変更</span></button>
      </div>
    );

    const editURL = (
      <div className="url-edit">
        <form onSubmit={this.handleSave} >
          <div className="control">
            <div className="is-block-mobile is-flex-tablet">
              <p className="text-url">{origin + path}</p>
              <input
                ref={node => this.inputUrl = node}
                className={`input ${ invalid ? 'is-danger' : 'is-success'}`}
                type="text"
                value={alias}
                onChange={this.handleAlias}
              />
            </div>
            {invalid && <p className="help is-danger text-right">{errorMessage}</p>}
          </div>
          <nav className="nav-text-edit level is-mobile" style={{marginTop: .5 + 'rem'}}>
            <div className="level-right">
              <button
                type="button"
                title="キャンセル"
                className="button is-text has-text-black button-edit level-item"
                onClick={this.handleCancel}
              >
                <span className="icon"><i className="fas fa-times" /></span><span>キャンセル</span>
              </button>
              <button
                type="button"
                title="保存"
                className="button button-edit level-item"
                onClick={this.handleSave}
              >
                <span className="icon"><i className="fas fa-check" /></span><span>保存</span>
              </button>
            </div>
          </nav>
        </form>
      </div>
    )

    return (
      <div className={`modal${active ? ' is-active' : ''}`} id="ModalUrl">
        <div className="modal-background"></div>
        <div className="modal-card">
        <header className="modal-card-head">
          <h5 className="modal-card-title">ボードのURL</h5>
          <button
            onClick={this.handleClose}
            className="delete is-pulled-right"
            aria-label="close"
          />
        </header>
          <section className="modal-card-body">
          {editing ? editURL : showURL }
          </section>
          <footer className="modal-card-foot">
            <button className={`button is-success${editing ? ' invisible' : ''}`} onClick={this.copyUrl} >
              <span className="icon"><i className="fas fa-link"></i></span><span>URLをコピー</span>
            </button>
          </footer>

        </div>
      </div>

    );
  }
}

export default ModalUrl;
