import { nlpCredentials } from '../assets/constants';

export const authNLP = () => {
  const endpoint = 'https://api.ce-cotoha.com/v1/oauth/accesstokens';
  const body = {
    ...nlpCredentials,
    grantType: 'client_credentials',
  }

  return fetch(endpoint, {
      method: "POST",
      mode: "cors",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(body),
  })
  .then(res => res.json())
  .then(response => response.access_token)
  .catch(error => console.log('Error:', error));
}

export const detectKeyword = (accessToken, text, maxNumber) => {
  const endpoint = 'https://api.ce-cotoha.com/api/dev/nlp/v1/keyword';
  const body = {
    document: text,
    type: 'default',
    do_segment: true,
    max_keyword_num: maxNumber,
  }

  return fetch(endpoint, {
      method: "POST",
      mode: "cors",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": `Bearer ${accessToken}`
      },
      body: JSON.stringify(body),
  })
  .then(res => res.json())
  .catch(error => console.log('Error:', error));
}
