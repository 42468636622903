import React, { Component } from 'react';

import {
  updateParagraph,
  deleteParagraph,
} from '../../lib/firestore';
import {
  analyzeSpeech,
} from '../../lib/app';
import noImage from '../../assets/images/thum_no_image.svg';

class Paragraph extends Component {
  constructor(props) {
    super(props);
    const { paragraph } = props;
    const textJapanese = paragraph.text || '';
    const textEnglish = paragraph.translated || '';
    this.state = {
      classNameFigure: '',
      sectionHeight: null,
      // textTags: [],
      even: false,
      openMenu: false,
      loadedImages: [],
      textJapanese,
      textEnglish,
      editingJapanese: false,
      editingEnglish: false,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentDidUpdate(prevProps, prevState){
    const { editingJapanese, editingEnglish } = this.state;
    const { paragraph } = this.props;
    const { text, translated } = paragraph;

    if (!editingJapanese && prevProps.paragraph.text !== text) {
      this.setState({ textJapanese: text || ''});
    }
    if (!editingEnglish && prevProps.paragraph.translated !== translated) {
      this.setState({ textEnglish: translated || ''});
    }

    if (editingJapanese && !prevState.editingJapanese) {
      this.textAreaJapanese && this.textAreaJapanese.focus();
    }
    if (editingEnglish && !prevState.editingEnglish) {
      this.textAreaEnglish && this.textAreaEnglish.focus();
    }
  }

  handleClick = event =>{
    if (this.menuParagraph && !this.menuParagraph.contains(event.target)) {
      this.toggleMenu(false);
    }
  }

  render() {
    const { paragraph, show, admin, maxiumImages } = this.props;

    if (!show) {
      return null;
    }

    const { text, imageSearchWord, translated, images, isTimestamp, createdAt } = paragraph;

    // // 日本語と英語非表示時に画像がないコンテンツをはじく
    // if (!this.props.japanese && !this.props.english && !(images && images.length)) {
    //   return <section className="empty" />
    //   // return <section className={this.state.even ? 'even' : ''} />
    // }

    if (isTimestamp && createdAt) {
      return (
        <time>{createdAt.toDate().toLocaleString()}</time>
      );
    }

    const { textJapanese, textEnglish ,editingJapanese, editingEnglish } = this.state;

    const japanese = (
      <div className={`text-block jp ${this.props.japanese ? '' : 'deactive'}`}>
        <p>
        {this.handleText(text, imageSearchWord)}
        </p>
        {admin && (
          <button
            title="テキスト変更"
            className="button button-text-edit is-inverted is-rounded is-small is-success"
            onClick={this.toggleEditJapanese}
          >
            <i className="fas fa-pen" />
          </button>
        )}
      </div>
    );

    const editFormJapanese = (
      <div className="text-block jp">
        <div className="field">
          <div className="control">
            <textarea
              ref={node => this.textAreaJapanese = node}
              className="textarea is-success"
              onChange={this.handleTextJapanese}
              value={textJapanese}
              onKeyPress={this.handleKeyPressJapanese}
            />
          </div>
        </div>
        <nav className="nav-text-edit level is-mobile">
          <div className="level-right">
              <button
                title="キャンセル"
                className="button is-text level-item"
                onClick={this.cancelEditJapanese}
              ><span className="icon"><i className="fas fa-times" /></span><span>キャンセル</span></button>
              <button
                title="保存"
                className="button is-success level-item"
                onClick={this.handleSaveJapanese}
              ><span className="icon"><i className="fas fa-check" /></span><span>保存</span></button>
          </div>
        </nav>
      </div>
    );

    const linesEnglish = translated ? translated.trim().split('\n') : [];

    const english = translated ? (
      <div className={`text-block en ${this.props.english ? '' : 'deactive'}`}>
        <p>
          {linesEnglish.map((line, index) => {
            if (!line && index < linesEnglish.length - 1 && !linesEnglish[index + 1]) {
              return null;
            }
            return (
              <React.Fragment key={index} >
                {line}
                <br />
              </ React.Fragment>
            )
          })}
        </p>
        {admin && (
          <button
            title="編集する"
            className="button button-text-edit is-inverted is-rounded is-small is-success"
            onClick={this.toggleEditEnglish}
          >
            <i className="fas fa-pen" />
          </button>
        )}

      </div>
    ) : null;

    const editFormEnglish = (
      <div className="text-block en">
        <div className="field">
          <div className="control">
            <textarea
              ref={node => this.textAreaEnglish = node}
              className="textarea is-success"
              onKeyPress={this.handleKeyPressEnglish}
              onChange={this.handleTextEnglish}
              value={textEnglish}
            >{textEnglish}</textarea>
          </div>
        </div>
        <nav className="nav-text-edit level is-mobile">
          <div className="level-right">
              <button
                title="キャンセル"
                className="button is-text level-item"
                onClick={this.cancelEditEnglish}
              ><span className="icon"><i className="fas fa-times" /></span><span>キャンセル</span></button>
              <button
                title="保存"
                className="button is-success level-item"
                onClick={this.handleSaveEnglish}
              ><span className="icon"><i className="fas fa-check" /></span><span>保存</span></button>
          </div>
        </nav>
      </div>
    );

    const imageBlock = images && images.length ? (
      <div className="img-block" ref={node => this.image = node}>
          <div className={`is-grid board-images-${maxiumImages}`}>
            {images.map((image, index) => {
              return index < maxiumImages ? (
                <figure className={ ~this.state.loadedImages.indexOf(index) ? 'append item' :''} key={index} >
                  <img
                    src={image.url}
                    alt={image.title}
                    onLoad={() => this.handleImage(index)}
                    onError={event => {
                      event.target.className = 'noimg';
                      event.target.src = noImage;
                    }}
                  />
                </figure>
              ) : null;
            })}
          </div>
      </div>
    ) : (
      <div className="img-block" ref={node => this.image = node}>
        <div className="is-grid board-no-images">
          <figure className="append item">
            <img
              className="noimg"
              src={noImage}
              alt="該当画像が見つかりませんでした。"
              onLoad={this.fixSectionHeight}
            />
          </figure>
        </div>
      </div>
    );

    const sectionStyle = { minHeight: this.state.sectionHeight };
    const { openMenu } = this.state;

    return (
      <div style={sectionStyle} >
        <div className="text-wrapper" >
          {admin && (
            <nav className="paragraph-menu" >
              <div
                ref={node => this.menuParagraph = node}
                className={`dropdown is-up${openMenu ? ' is-active' : '' }`}
                onClick={() => this.toggleMenu(!openMenu)}
              >
                <div className="dropdown-trigger">
                    <button title="段落メニュー" className="button is-text has-text-white is-small is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span><i className="icon fa fa-ellipsis-h"></i></span>
                  </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                  <div className="dropdown-content" >
                    {
                      // eslint-disable-next-line
                      <a className="dropdown-item" onClick={this.handleDelete} >
                          <i className="fas fa-trash" /> 削除する
                      </a>
                    }
                  </div>
                </div>
              </div>
            </nav>
          )}
          {editingJapanese ? editFormJapanese : japanese}
          {editingEnglish ? editFormEnglish : english}
        </div>
        {images && imageBlock}
      </div>
    );
  }

  handleImage = index => {
    this.addClassNameFigure(index);
    this.fixSectionHeight();
  }

  // addClassNameFigure = index => this.setState({ classNameFigure: 'append' });
  addClassNameFigure = index => this.setState({ loadedImages: [ ...this.state.loadedImages, index] });

  fixSectionHeight = () => {
    if (this.image) {
      const height = this.image.offsetHeight;
      this.setState({ sectionHeight: height });
    }
  }

  isEven = () =>  {
    const even =  this.props.index % 2 === 0
    this.setState({ even });
  }

  handleText = (text, imageSearchWord) => {
    // const { text, imageSearchWord } = this.props.paragraph;
    if (text) {
      let keywords;
      if (imageSearchWord) {
        keywords = [{ form: imageSearchWord }];
      }
      const splittedText = this.splitKeyword(text, keywords);
      const textTags = this.createTag(splittedText);
      return textTags;
    } else {
      return ''
    }
  }

  splitKeyword = (text, keywords) => {
    const keywordTag = '<keyword>';
    keywords && keywords.map((item, index) => {
      const keyword = item.form;
      const regExp = new RegExp(keyword, 'g');
      text = text.replace(regExp, keywordTag + keyword + keywordTag)

      // if (this.detectHalf(keyword)) {
      //   const zenkaku = this.changeToZenkaku(keyword);
      //   console.log('moto', keyword, 'zenaku',zenkaku);
      //   const zenkakuRegExp = new RegExp(zenkaku, 'g');
      //   text = text.replace(zenkakuRegExp, keywordTag + zenkaku + keywordTag)
      // }
      return item;
    });
    return text.split(keywordTag)
  }

  createTag = array => {
    const keywords = this.props.paragraph.keywords || [];
    return array.map((text, index)=> {
      let tag = text;
      if (keywords.map(item => item.form).indexOf(text) >= 0) {
        tag = <span key={index} className="keyword">{text}</span>
      }
      return tag;
    })
  }

  toggleEditJapanese = () => this.setState({ editingJapanese: !this.state.editingJapanese });

  toggleEditEnglish = () => this.setState({ editingEnglish: !this.state.editingEnglish });

  handleTextJapanese = event => this.setState({ textJapanese: event.target.value });

  handleTextEnglish = event => this.setState({ textEnglish: event.target.value });

  cancelEditJapanese = () => this.setState({ textJapanese: this.props.paragraph.text, editingJapanese: false });

  cancelEditEnglish = () => this.setState({ textEnglish: this.props.paragraph.translated, editingEnglish: false });

  handleSaveJapanese =  event => {
    event && event.preventDefault();
    event && event.stopPropagation();
    const { textJapanese } = this.state;

    const { text, id } = this.props.paragraph;
    if (textJapanese.trim() !== text) {
      updateParagraph(id, { text: textJapanese.trim() });
      analyzeSpeech(id, textJapanese, true); // 日本語が変更されたら英語も再翻訳する
    }
    this.setState({ editingJapanese: false});
  }

  handleKeyPressJapanese = event => {
    event && event.stopPropagation();
    if (event.key === 'Enter' && event.shiftKey) {
      event && event.preventDefault();
      this.handleSaveJapanese()
    }
  }

  handleSaveEnglish =  event => {
    event && event.preventDefault();
    event && event.stopPropagation();
    const { textEnglish } = this.state;

    const { translated, id } = this.props.paragraph;
    if (textEnglish.trim() !== translated) {
      updateParagraph(id, { translated: textEnglish.trim() });
    }
    this.setState({ editingEnglish: false});
  }

  handleKeyPressEnglish = event => {
    event && event.stopPropagation();
    if (event.key === 'Enter' && event.shiftKey) {
      event && event.preventDefault();
      this.handleSaveEnglish()
    }
  }

  toggleMenu = (openMenu = !this.state.openMenu) => {
    if (openMenu !== this.state.openMenu) {
      this.setState({ openMenu });
    }
  }

  handleDelete = event => deleteParagraph(this.props.paragraph.id);
}

export default Paragraph;
