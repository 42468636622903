import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { logIn } from '../../lib/firestore';
import { firebaseErrorResponses } from '../../assets/constants';

class ModalLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      pass: '',
      invalidEmail: false,
      loading: false,
    };
  }

  handleEmail = event => this.setState({ email: event.target.value })

  handlePass = event => this.setState({ pass: event.target.value })

  handleLogin = async event => {
    event && event.preventDefault();
    event && event.stopPropagation();
    this.setState({ loading: true });
    const { email, pass } = this.state;
    const res = await logIn(email, pass);
    const { errorCode } = res;
    if (!errorCode) {
      const callback = () => {
        const { handleClose } = this.props;
        handleClose && handleClose();
        const stayAfterLogin = Boolean(~window.location.pathname.indexOf('/board'));
        !stayAfterLogin && this.openDashboard();
      }
      this.clearState(callback)
    } else {
      let errorMessage = firebaseErrorResponses[errorCode];
      if (!errorMessage) {
        errorMessage = firebaseErrorResponses['fallback-login'];
      }
      this.setState({ errorMessage, loading: false })
    }
  }

  handleSwitch = () => this.clearState(this.props.switchTab)

  clearState = callback => {
    this.setState({
      email: '',
      pass: '',
      invalidEmail: false,
      errorMessage: '',
      loading: false,
    }, () => callback && callback())
  }

  handleClose = event => {
    event && event.preventDefault();
    event && event.stopPropagation();
    const { handleClose } = this.props;
    this.clearState(handleClose)
  }

  openDashboard = () => this.props.history.push({ pathname: `${process.env.PUBLIC_URL}/dashboard` })

  render() {
    const { email, pass, invalidEmail, errorMessage, loading } = this.state;
    const { show } = this.props;
    return (
      <div className={`modal${ show ? ' is-active' : ''}`} id="ModalLogin">
          <div className="modal-background"></div>
          <div className="modal-card">
            <section className="modal-card-body">
                <button
                  onClick={this.handleClose}
                  className="delete is-pulled-right"
                  aria-label="close"
                />
                <section className="section">
                    <div className="tabs is-rounded is-centered">
                        <ul>
                          <li>
                            {
                              // eslint-disable-next-line
                              <a className="button is-rounded" onClick={this.handleSwitch} >新規登録</a>
                            }
                          </li>
                          <li className="is-active">
                            {
                              // eslint-disable-next-line
                              <a className="button is-rounded">ログイン</a>
                            }
                          </li>
                        </ul>
                    </div>
                    <form onSubmit={this.handleLogin} >
                      <div className="field">
                        <label className="label">Email</label>
                        <div className="control has-icons-left">
                          <input
                            className={`input${ invalidEmail ? ' is-danger' : ''}`}
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={this.handleEmail}
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-envelope"></i>
                          </span>
                        </div>
                        {invalidEmail && <p className="help is-danger">Emailアドレスが正しくありません。</p>}
                      </div>

                      <div className="field">
                          <label className="label">パスワード</label>
                          <div className="control">
                            <input className="input" type="password" value={pass} onChange={this.handlePass}/>
                          </div>
                      </div>
                      {
                      // <div className="field">
                      //   <label className="checkbox is-size-7">
                      //     <input type="checkbox"/>
                      //     次回から自動的にログインする
                      //   </label>
                      // </div>
                      }
                      {errorMessage && <p className="help is-danger">{errorMessage}</p>}
                      <div className="field columns is-half" style={{ margin: '1rem 0' }}>
                        <div className="control column is-half">
                          <button
                            onClick={this.handleClose}
                            className="button is-fullwidth is-fullwidth"
                          >キャンセル</button>
                        </div>
                        <div className="control column">
                          <button
                            className={`button is-fullwidth is-success is-fullwidth${loading ? ' is-loading' : ''}`}
                            onClick={this.handleLogin}
                          >ログイン</button>
                        </div>
                      </div>
                  </form>
                </section>
            </section>
          </div>
       </div>
   );
  }
}

export default withRouter(ModalLogin);
