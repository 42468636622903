import { connect } from 'react-redux';

import * as app from '../modules/app';
import Header from '../components/Header/Header';

const mapStateToProps = state => {
  const {
    loginUser,
    modal,
  } = state.app;

  return {
    loginUser,
    modal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showModalRegister: () => dispatch(app.showModalRegister()),
    showModaLogin: () => dispatch(app.showModaLogin()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
