import React from 'react';
import { withRouter } from 'react-router';

const Footer = props => {
  const openTerms = () => props.history.push({ pathname: `${process.env.PUBLIC_URL}/terms` })

  return (
    <footer className="footer">
				<div className="content has-text-centered">
          <div className="footer-content">
            <nav className="footer-nav">
              {
                // eslint-disable-next-line
                <a
                  onClick={openTerms}
                >
                <span className="is-size-7">利用規約</span>
                </a>
              }
                <span className="is-inline-block">|</span>
                <a
                  href="https://www.ntt.com/about-us/hp/privacy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                <span className="is-size-7">プライバシーポリシー</span>
              </a>
            </nav>
            <p className="is-size-7">
                Subz. はNTT Comの自然言語解析AI <span className="is-inline-block"> 「COTOHA®」を利用しています。</span>
            </p>
          </div>
        </div>
		</footer>
  );
};

export default withRouter(Footer);
