////////////////////////////////////////////////////////////
// definition
//

// user = {
//   id,
//   name,
//   email,
//   icon,
// }

// board = {
//   title: String
//   createdAt: firebase.firestore.FieldValue.serverTimestamp();
//   updatedAt: firebase.firestore.FieldValue.serverTimestamp();
//   createdBy: String(UserID)
//   urlAlias: String
//   admin: userid: true
//   viewed: Object[ userId: Num(timestamp)]
//   clipped: Object[ userId: Num(timestamp)]
//   live: Boolean
// }

// paragraph ={
//   boardId
//   userId
//   text
//   keywords
//   translated
//   images
//   imageSearchword
//   isTimestamp
//   createdAt
//   updatedAt
// }

// comments = {
//   boardId
//   text
//   userId
//   guestName
//   createdAt
//   updatedAt
// }

////////////////////////////////////////////////////////////

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import { firebaseConfig, guestUserId } from '../assets/constants';
firebase.initializeApp(firebaseConfig);

export const preparedKeywords = {};

const fetchKeywords = async () => {
  const db = firebase.firestore();
  await db.collection('keywords')
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        const { id } = doc;
        const object = doc.data();
        preparedKeywords[id] = object;
      });
    });
}

fetchKeywords();

const collectionUser = 'users';
export const collectionBoard = 'boards';
export const collectionComment = 'comments';
export const collectionParagraph = 'paragraphs';

export const uploadUserIcon = (userId, blob) => {
  const storageRef = firebase.storage().ref();
  const imagesRef = storageRef.child(`icons/${userId}.png`);
  return imagesRef.put(blob).then(() => imagesRef.getDownloadURL())
}

export const signUp = (email, pass, name) => {
  return firebase.auth().createUserWithEmailAndPassword(email, pass)
    .then(res => {
      const { user } = res;
      createUser(user.uid, email, name);
      return user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      return { errorCode, errorMessage };
    });
}

export const createUser = (uid, email, name = '') => {
  const db = firebase.firestore();
  const ref = db.collection(collectionUser).doc(uid);
  const createdAt = firebase.firestore.FieldValue.serverTimestamp();
  const doc = {
    createdAt,
    updatedAt: createdAt,
    name,
    email,
    icon: null,
  }
  return ref.set(doc);
}

export const updateUser = (userId, doc) => {
  if (userId && userId !== guestUserId && doc) {
    console.log('updateUser', userId, doc)
    const db = firebase.firestore();
    const ref = db.collection(collectionUser).doc(userId);
    const updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    return ref.update({
      ...doc,
      updatedAt,
    });
  } else {
    console.log('doc is undefined in updateUser function');
  }
}

//最近ログインしている必要がある https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user
export const updateEmail = email => {
  const user = firebase.auth().currentUser;
  user.updateEmail(email).then(() => {
    updateUser(user.uid, { email })
  }).catch(error => {
    console.log(error);
    return error;
  });
}

//最近ログインしている必要がある https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user
export const updatePass = pass => {
  const user = firebase.auth().currentUser;
  user.updatePassword(pass).catch(error => {
    console.log(error);
    return error;
  });
}

const deleteUserDocument = userId => {
  // TODO なぜか更新されない
  console.log('delete', userId)
  const doc = { deleted: true };
  updateUser(userId, doc);
}

export const deleteUser = () => {
  const user = firebase.auth().currentUser;
  const { uid } = user;
   user.delete()
    .then(() => deleteUserDocument(uid))
    .catch(error => {
      console.log(error);
      return error;
    });
}

export const fetchUser = uid => {
  const db = firebase.firestore();
  const ref = db.collection(collectionUser).doc(uid);
  return ref.get().then(doc => {
    let user = null;
    if (doc.exists) {
      const { id } = doc;
      user = { ...doc.data(), id }
    }
    return user;
  })
}

export const reAuth = password => {
  const user = firebase.auth().currentUser;
  const credential = firebase.auth.EmailAuthProvider.credential(user.email, password)
  return user.reauthenticateAndRetrieveDataWithCredential(credential)
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      return { errorCode, errorMessage };
  });
}

export const logIn = (email, password) => {
  return firebase.auth().signInWithEmailAndPassword(email, password)
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      return { errorCode, errorMessage };
    });
}

export const logOut = () => firebase.auth().signOut();

export const createBoard = (userId = guestUserId) => {
  const db = firebase.firestore();
  const collection = db.collection(collectionBoard);
  const createdAt = firebase.firestore.FieldValue.serverTimestamp();

  const admin = [ userId ];
  const viewed = {}
  const timestamp = Date.now();
  viewed[userId] = timestamp;

  const doc = {
    title: '',
    createdAt,
    updatedAt: createdAt,
    createdBy: userId,
    urlAlias: null,
    admin,
    viewed,
    clipped: {},
    live: false,
  }
  return collection.add(doc).then(docRef => docRef.id);
}

export const updateBoard = (boardId, doc) => {
  if (doc) {
    const db = firebase.firestore();
    const ref = db.collection(collectionBoard).doc(boardId);
    const updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    return ref.update({
      ...doc,
      updatedAt,
    });
  } else {
    console.log('doc is undefined in updateDocument function');
  }
}

export const viewBoard = (boardId, userId) => {
  if (userId) {
    const key = `viewed.${userId}`;
    const timestamp = Date.now();
    const doc = { [key]: timestamp }
    return updateBoard(boardId, doc)
  }
}

export const clipBoard = (boardId, userId) => {
  if (userId && userId !== guestUserId) {
    const key = `clipped.${userId}`;
    const timestamp = Date.now();
    const doc = { [key]: timestamp }
    return updateBoard(boardId, doc)
  }
}

export const unclipBoard = (boardId, userId) => {
  const key = `clipped.${userId}`;
  const doc = { [key]: 0 }
  return updateBoard(boardId, doc)
}

export const updateBoardTitle = (boardId, title) => updateBoard(boardId, { title })

export const setAlias = (boardId, urlAlias) => updateBoard(boardId, { urlAlias })

export const getBoardIdByAlias = urlAlias => {
  const db = firebase.firestore();
  const collection = db.collection(collectionBoard);
  return collection.where('urlAlias', '==', urlAlias).get()
    .then(querySnapshot => {
      let boardId = null;
      querySnapshot.forEach(doc => {
        const { id } = doc;
        boardId = id;
      });
      return boardId;
    })
}

export const deleteBoard = boardId => {
  const db = firebase.firestore();
  return db.collection(collectionBoard).doc(boardId).delete();
}

export const fetchThumbnail = boardId => {
  const db = firebase.firestore();
  const collection = db.collection(collectionParagraph);
  return collection.where('boardId', '==', boardId).orderBy('createdAt').get()
    .then(querySnapshot => {
      let image = null;
      querySnapshot.forEach(doc => {
        if (!image) {
          const { id } = doc;
          const paragraph = { ...doc.data(), id }
          if (paragraph.images && paragraph.images.length) {
            image = paragraph.images[0];
          }
        }
      });
      return image;
    })
}

export const fetchMyBoards = userId => {
  const db = firebase.firestore();
  const collection = db.collection(collectionBoard);
  return collection.where('createdBy', '==', userId).orderBy('createdAt', 'desc').get()
    .then(querySnapshot => {
      const boards = [];
      querySnapshot.forEach(doc => {
        const { id } = doc;
        const object = { ...doc.data(), id }
        boards.push(object);
      });
      return boards
    })
}

export const fetchRecentViewedBoards = userId => {
  const db = firebase.firestore();
  const collection = db.collection(collectionBoard);
  const key = `viewed.${userId}`;
  return collection.where(key, '>', 0).orderBy(key, 'desc').get()
    .then(querySnapshot => {
      const boards = [];
      querySnapshot.forEach(doc => {
        const { id } = doc;
        const object = { ...doc.data(), id }
        boards.push(object);
      });
      return boards
    })
}

export const fetchClippedBoards = userId => {
  const db = firebase.firestore();
  const collection = db.collection(collectionBoard);
  const key = `clipped.${userId}`;
  return collection.where(key, '>', 0).orderBy(key, 'desc').get()
    .then(querySnapshot => {
      const boards = [];
      querySnapshot.forEach(doc => {
        const { id } = doc;
        const object = { ...doc.data(), id }
        boards.push(object);
      });
      return boards
    })
}

export const setLive = (boardId, live) => {
  const db = firebase.firestore();
  const ref = db.collection(collectionBoard).doc(boardId);
  const updatedAt = firebase.firestore.FieldValue.serverTimestamp();
  const doc = { live, updatedAt };
  return ref.update(doc);
}

export const createTimestampStart = (boardId, userId) => {
  const db = firebase.firestore();
  const path = collectionParagraph
  const collection = db.collection(path);
  const createdAt = firebase.firestore.FieldValue.serverTimestamp();
  const doc = {
    boardId,
    userId,
    isTimestamp: true,
    start: true,
    createdAt,
    updatedAt: createdAt,
  }
  return collection.add(doc).then(docRef => docRef.id);
}

export const createParagraph = (boardId, userId, speech) => {
  const db = firebase.firestore();
  const path = collectionParagraph
  const collection = db.collection(path);
  const createdAt = firebase.firestore.FieldValue.serverTimestamp();
  const doc = {
    boardId,
    userId,
    text: speech,
    keywords: null,
    translated: null,
    images: null,
    imageSearchword: null,
    isTimestamp: false,
    createdAt,
    updatedAt: createdAt,
  }
  return collection.add(doc).then(docRef => docRef.id);
}

export const updateParagraph = (paragraphId, doc) => {
  if (doc) {
    const db = firebase.firestore();
    const ref = db.collection(collectionParagraph).doc(paragraphId);
    const updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    return ref.update({
      ...doc,
      updatedAt,
    });
  } else {
    console.log('doc is undefined in updateDocument function');
  }
}

export const deleteParagraph = paragraphId => {
  const db = firebase.firestore();
  return db.collection(collectionParagraph).doc(paragraphId).delete();
}

export const setResultsNLPToParagraph = (paragraphId, keywords) => {
  if (keywords) {
    const doc = { keywords };
    updateParagraph(paragraphId, doc);
  }
}

export const setResultsTranslationToParagraph = (paragraphId, json) => {
  if(json && json.result && json.result.translation) {
    const translated = json.result.translation;
    const doc = { translated };
    updateParagraph(paragraphId, doc);
  }
}

export const setResultsGoogleTranslationToParagraph = (paragraphId, json) => {
  if(json && json.data && json.data.translations && json.data.translations.length) {
    const translated = json.data.translations[0].translatedText;
    const doc = { translated };
    updateParagraph(paragraphId, doc);
  }
}

export const setImageGoogleToParagraph = (paragraphId, word, items) => {
  let images = null;

  if (items && items.length) {
    images = items.map(item => {
      const { link, displayLink, title, mime, image } = item;
      return {
        google: true,
        url: link,
        title,
        displayLink,
        mime,
        ...image,
      };
    })
  }
  const imageSearchWord = word;
  const doc = { images, imageSearchWord };
  updateParagraph(paragraphId, doc);
}

export const clearImage = paragraphId => {
  const doc = { images: [], imageSearchWord: '' };
  updateParagraph(paragraphId, doc);
}

// comments = {
//   boardId
//   text
//   userId
//   guestName
//   createdAt
//   updatedAt
// }

export const createComment = (boardId, userId, text) => {
  const db = firebase.firestore();
  const collection = db.collection(collectionComment);
  const createdAt = firebase.firestore.FieldValue.serverTimestamp();
  const doc = {
    boardId,
    userId,
    text,
    createdAt,
    updatedAt: createdAt,
  }
  return collection.add(doc).then(docRef => docRef.id);
}

export const createCommentAsGuest = (boardId, userName = 'ゲスト', text) => {
  const db = firebase.firestore();
  const collection = db.collection(collectionComment);
  const createdAt = firebase.firestore.FieldValue.serverTimestamp();
  const doc = {
    boardId,
    userId: guestUserId,
    guestName: userName,
    text,
    createdAt,
    updatedAt: createdAt,
  }
  return collection.add(doc).then(docRef => docRef.id);
}



////////////////////////////////////////////////////////////
// deprecated
////////////////////////////////////////////////////////////

const collectionName = 'speeches';
const collectionState = 'states';

export const startLive = boardName => {
  const db = firebase.firestore();
  const ref = db.collection(collectionState).doc(boardName);
  const updatedAt = firebase.firestore.FieldValue.serverTimestamp();
  const doc = { live: true, updatedAt };
  return ref.set(doc);
}

export const finishLive = boardName => {
  const db = firebase.firestore();
  const ref = db.collection(collectionState).doc(boardName);
  const updatedAt = firebase.firestore.FieldValue.serverTimestamp();
  const doc = { live: false, updatedAt };
  return ref.set(doc);
}

export const addTimestampStart = boardId => {
  const db = firebase.firestore();
  const path = `${collectionName}/${boardId}/items`;
  const collection = db.collection(path);
  const createdAt = firebase.firestore.FieldValue.serverTimestamp();
  const doc = {
    isTimestamp: true,
    createdAt,
    start: true,
  }
  return collection.add(doc).then(docRef => docRef.id);
}

export const addTimestampEnd = boardId => {
  const db = firebase.firestore();
  const path = `${collectionName}/${boardId}/items`;
  const collection = db.collection(path);
  const createdAt = firebase.firestore.FieldValue.serverTimestamp();
  const doc = {
    isTimestamp: true,
    createdAt,
    end: true,
  }
  return collection.add(doc).then(docRef => docRef.id);
}

export const createDemo = () => {
  const db = firebase.firestore();
  const collection = db.collection(collectionName);
  const createdAt = firebase.firestore.FieldValue.serverTimestamp();
  const doc = {
    createdAt,
  }
  return collection.add(doc).then(docRef => docRef.id);
}

export const addDocument = (boardName, speech) => {
  const db = firebase.firestore();
  const path = `${collectionName}/${boardName}/items`
  const collection = db.collection(path);
  const createdAt = firebase.firestore.FieldValue.serverTimestamp();
  const doc = {
    isTimestamp: false,
    text: speech,
    createdAt,
    keywords: null,
    translated: null,
  }
  return collection.add(doc).then(docRef => docRef.id);
}

export const updateDocument = (boardName, id, doc) => {
  if (doc) {
    const db = firebase.firestore();
    const ref = db.collection(collectionName).doc(boardName).collection('items').doc(id);
    const updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    return ref.update({
      ...doc,
      updatedAt,
    });
  } else {
    console.log('doc is undefined in updateDocument function');
  }
}

export const setResultsNLP = (boardName, id, keywords) => {
  if (keywords) {
    const doc = { keywords };
    updateDocument(boardName, id, doc);
  }
}

export const setResultsTranslation = (boardName, id, json) => {
  if(json && json.result && json.result.translation) {
    const translated = json.result.translation;
    const doc = { translated };
    updateDocument(boardName, id, doc);
  }
}

export const setImageGoogle = (boardName, id, word, json) => {
  const { items } = json;

  if (items.length) {
    const images = items.map(item => {
      const { link, displayLink, title, mime, image } = item;
      return {
        google: true,
        url: link,
        title,
        displayLink,
        mime,
        ...image,
      };
    })

    const imageSearchWord = word;
    const doc = { images, imageSearchWord };
    updateDocument(boardName, id, doc);
  }
}

export const setImage = (boardName, id, word, json) => {
  const { items } = json;
  const images = []
  if (items.length) {
    const item = items[0];
    const { previewUrl, photoId, title, author} = item;
    const image = {
      url: previewUrl,
      amanaId: photoId,
      title,
      author,
    };
    images.push(image);
  }
  const imageSearchWord = word;
  const doc = { images, imageSearchWord };
  updateDocument(boardName, id, doc);
}

export const deleteDocument = (boardName, id) => {
  const db = firebase.firestore();
  const path = `${collectionName}/${boardName}/items`
  return db.collection(path).doc(id).delete();
}

export const deleteAll = (boardName, callback) => {
  const db = firebase.firestore();
  const tasks = [];
  const path = `${collectionName}/${boardName}/items`
  db.collection(path)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        const task = deleteDocument(boardName, doc.id);
        tasks.push(task)
      });
    })
    .then(() => {
      if (callback) {
        Promise.all(tasks).then(callback);
      }
    })
}
