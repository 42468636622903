import { connect } from 'react-redux';

import * as app from '../modules/app';
import Setting from '../components/Dashboard/Setting';

const mapStateToProps = state => {
  const {
    loginUser,
  } = state.app;

  return {
    loginUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showModalDelete: () => dispatch(app.showModalDelete()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Setting);
