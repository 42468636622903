const imagePath = ' /events/';

export const eventWordsDict = {
  '電化製品': [
    `${imagePath}denkaseihin1.jpg`,
    `${imagePath}denkaseihin2.jpg`,
  ],
  'カルチュアコンビニエンスクラブ': [
    `${imagePath}CCC1.png`,
    `${imagePath}tcard.jpg`,
  ],
  'カルチャーコンビニエンスクラブ': [
    `${imagePath}CCC1.png`,
    `${imagePath}tcard.jpg`,
  ],
  'CCC': [
    `${imagePath}CCC1.png`,
    `${imagePath}tcard.jpg`,
  ],
  'ccc': [
    `${imagePath}CCC1.png`,
    `${imagePath}tcard.jpg`,
  ],
  '蔦屋家電': [
    `${imagePath}tsutayakaden1.png`,
    `${imagePath}tsutayakaden2.png`,
  ],
  'つたやかでん': [
    `${imagePath}tsutayakaden1.png`,
    `${imagePath}tsutayakaden2.png`,
  ],
  'ｔｓｕｔａｙａ家電': [
    `${imagePath}tsutayakaden1.png`,
    `${imagePath}tsutayakaden2.png`,
  ],
  '分析': [
    `${imagePath}bunseki1.png`,
    `${imagePath}bunseki2.jpg`,
  ],
  '商品開発': [
    `${imagePath}product1.jpg`,
    `${imagePath}product2.jpg`,
  ],
  'マーケティング': [
    `${imagePath}mkt1.jpg`,
    `${imagePath}mkt2.jpg`,
  ],
}

export default eventWordsDict;
