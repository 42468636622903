import React, { Component } from 'react';
import { withRouter } from 'react-router';

import HeaderContainer from '../../containers/HeaderContainer';
import Footer from '../Footer';
import defaultUserIcon from '../../assets/images/thum_user.svg';
import { guestUserId } from '../../assets/constants';
import {
  updateUser,
  uploadUserIcon,
} from '../../lib/firestore';

class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      icon: '',
      email: '',
      pass: '',
      passConfirm: '',
      errorMessage: null,
      fetched: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.validateUser();

    this.hiddenInput.addEventListener("change", e => {
      const { files } = e.target;

      if (files && files) {
        const blob = new Blob(files, { type: "image/png" });
        const blobURL = URL.createObjectURL(blob);
        this.setState({ icon: blobURL, blob });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    this.validateUser();
  }

  validateUser = () => {
    const { loginUser } = this.props;

    if (!loginUser) {
      return;
    }

    if (loginUser.id === guestUserId ) {
      this.openTop();
    } else if (!this.state.fetched) {
      this.setState({
        ...loginUser,
        fetched: true,
      })
    }
  }

  handleName = event => this.setState({ name: event.target.value })

  handleEmail = event => this.setState({ email: event.target.value })

  handlePass = event => this.setState({ pass: event.target.value })

  handlePassConfirm = event => this.setState({ passConfirm: event.target.value })

  handleSave = async event => {
    event && event.preventDefault();
    event && event.stopPropagation();
    const name = this.state.name && this.state.name.trim();

    if (!name) {
      this.setState({
        errorMessage: '名前を入力してください。',
      });
      return;
    }

    this.setState({ loading: true })

    const { blob } = this.state;
    const { loginUser } = this.props;
    const { id } = loginUser;

    const doc = {};
    if (name && name !== loginUser.name) {
      doc.name = name;
    }
    if (blob) {
      const icon = await uploadUserIcon(id, blob);
      doc.icon = icon;
    }

    if (Object.keys(doc).length) {
      updateUser(id, doc);
    }

    this.openDashboard();
    this.setState({ loading: false });
  }

  // handleSubmit(event) {
  //   event.preventDefault();
  //   alert(
  //     `Selected file - ${
  //       this.fileInput.current.files[0].name
  //     }`
  //   );
  // }

  handleUpload = event => {
    event && event.stopPropagation();
    this.hiddenInput.click();
  }

  openTop = () => this.props.history.push({ pathname: `${process.env.PUBLIC_URL}/` });

  openDashboard = () => this.props.history.push({ pathname: `${process.env.PUBLIC_URL}/dashboard` })

  render() {
    const {
      name,
      // email,
      // pass,
      // passConfirm,
      // invalidEmail,
      icon,
      errorMessage,
      loading,
    } = this.state;

    const { showModalDelete } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer />
        <div className="content-wrapper">
          <div className="content-insede ">
            <section className="section has-background-light">
              <div className="container">
                  <div className="columns is-centered">
                    <div className="column is-two-fifths-desktop is-half-tablet">
                        <h2 className="title has-text-centered">
                            ユーザー設定
                        </h2>
                        <div className="card">
                            <div className="card-content">
                              <div className="field has-text-centered">
                                <figure className="image icon-prof has-text-centered is-96x96" style={{ margin: '0 auto 1rem' }}>
                                  <img className="image is-96x96 is-rounded clickable" src={icon || defaultUserIcon} alt='icon' onClick={this.handleUpload} />
                                </figure>
                                <button className="button is-text is-small" onClick={this.handleUpload} >
                                  プロフィール画像を変更
                                </button>
                                <input
                                  className="hidden"
                                  type="file"
                                  ref={input => this.hiddenInput = input}
                                  accept=".jpg, .png, image/jpeg, image/png"
                                />
                              </div>
                              <form onSubmit={this.handleSave} >
                                <div className="field">
                                    <label className="label">お名前</label>
                                    <div className="control">
                                    <input
                                      className="input"
                                      type="text"
                                      placeholder="お名前"
                                      value={name}
                                      onChange={this.handleName}
                                    />
                                    </div>
                                </div>
                                {
                                // <div className="field">
                                //   <label className="label">Email</label>
                                //   <div className="control has-icons-left">
                                //   <input
                                //     className={`input${invalidEmail ? ' is-danger' : ''}`}
                                //     type="email"
                                //     placeholder="Email"
                                //     value={email}
                                //     onChange={this.handleEmail}
                                //   />
                                //     <span className="icon is-small is-left">
                                //       <i className="fas fa-envelope"></i>
                                //     </span>
                                //   </div>
                                // </div>
                                //
                                // <div className="field">
                                //     <label className="label">パスワード</label>
                                //     <div className="control">
                                //       <input className="input" type="password" value={pass} onChange={this.handlePass}/>
                                //     </div>
                                // </div>
                                //
                                // <div className="field">
                                //     <label className="label">パスワード確認</label>
                                //     <div className="control">
                                //       <input
                                //         className="input"
                                //         type="password"
                                //         value={passConfirm}
                                //         onChange={this.handlePassConfirm}
                                //       />
                                //     </div>
                                // </div>
                                }
                                {errorMessage && <p className="help is-danger">{errorMessage}</p>}
                                <div className="field columns" style={{ margin: `1rem 0` }}>
                                  <div className="control column is-half is-offset-one-quarter">
                                    <button
                                      className={`button is-success is-fullwidth${loading ? ' is-loading' : ''}`}
                                      onClick={this.handleSave}
                                    >保存する</button>
                                  </div>
                                </div>
                              </form>
                              <div className="field">
                                <div className="control has-text-centered">
                                  <button className="button is-text is-small" onClick={showModalDelete} >
                                    アカウントを削除する
                                  </button>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                  </div>
                  <div className="has-text-centered">
                    {
                      // eslint-disable-next-line
                      <a className="button" onClick={this.openDashboard} >
                        <span>ダッシュボードに戻る</span><span className="icon"><i className="fa fa-arrow-alt-circle-right" /></span>
                      </a>
                    }
                  </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(Setting);
