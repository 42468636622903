export const firebaseConfigProd = {
  apiKey: "AIzaSyAhFRbDtg_MhA_By55nPEYsbPzREvXPCgE",
  authDomain: "cotoha-demo.firebaseapp.com",
  databaseURL: "https://cotoha-demo.firebaseio.com",
  projectId: "cotoha-demo",
  storageBucket: "cotoha-demo.appspot.com",
  // messagingSenderId: "1040843104084",
};

export const firebaseConfigDev = {
  apiKey: "AIzaSyAUdP4Tj_S5a8lzHz_uxy1sb5mCPM5x1Bo",
  authDomain: "subz-dev.firebaseapp.com",
  databaseURL: "https://subz-dev.firebaseio.com",
  projectId: "subz-dev",
  storageBucket: "subz-dev.appspot.com",
  // messagingSenderId: "259776288465"
};

export const firebaseConfig = process.env.REACT_APP_MODE === 'prod' ? firebaseConfigProd : firebaseConfigDev;

export const nlpCredentials = {
  clientId: '7q1sQGcMofdjA49iH0JUBOVdofUC8RfS',
  clientSecret: 'oy8f9C497tRLNPdu',
};

export const translationCredentials = {
  clientId: 'kAPKshMdb90YaOZ5C3pcxAhW98F5H6uJ',
  clientSecret: '6owxcR686Lia0rls',
};

export const asrCredentials = {
  clientId: 'fbzf7TW8gEEgYoCudASBi2CmGNCJJ0f4',
  clientSecret: '67yCgMWUkCdpw7AG',
};

export const googleCloudKey = 'AIzaSyClL_59cm-ERjB-l5lQIQG3s-l9u1F7jD4';

export const guestUserId = 'guest';

export const guestUserName = 'ゲスト';

export const guestUser = {
  id: guestUserId,
  name: guestUserName,
};

export const defaultBoardTitle = '無題のボード';

export const firebaseErrorResponses = {
  'auth/email-already-in-use': 'メールアドレスはすでに使用されています。',
  'auth/invalid-email': 'メールアドレスの形式に誤りがあります。',
  'auth/weak-password': 'パスワードは6文字以上である必要があります。',
  'auth/user-disabled': 'ユーザーは無効化されています。',
  'auth/user-not-found': 'ユーザーがみつかりませんでした。',
  'auth/wrong-password': 'パスワードが正しくありませんでした。',
  'fallback-signup': 'ユーザーの新規作成に失敗しました。お手数ですが、再度実行してください。',
  'fallback-login': 'ログインに失敗しました。お手数ですが、再度実行してください。',
}
