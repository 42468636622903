import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

import CommentCard from './CommentCard';
import {
  collectionComment,
} from '../../lib/firestore';
import { addScrollHeightResizeListener, removeScrollHeightResizeListener } from '../../lib/scroll';

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      firstScroll: true,
      existedCommentsCount: undefined,
    };
  }

  componentDidMount() {
    if (this.props.boardId) {
      this.subscribeDB(this.props.boardId);
    }
    addScrollHeightResizeListener(this.comments, this.handleHeight);
  }

  componentDidUpdate(prevProps) {
    const { boardId } = prevProps;
    if (!boardId && this.props.boardId) {
      this.subscribeDB(this.props.boardId);
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeComments) {
      this.unsubscribeComments();
    }
    removeScrollHeightResizeListener(this.comments);
  }

  subscribeDB = boardId => {
    this.unsubscribeComments = this.fetchComments(boardId);
  }


  fetchComments = boardId => {
    const db = firebase.firestore();
    return db.collection(collectionComment).where('boardId', '==', boardId).orderBy('createdAt')
      .onSnapshot(querySnapshot => {
        const comments = querySnapshot.docs.map((doc, index) => {
            const { id } = doc;
            const object = {
              ...doc.data(),
              id,
              index,
            }
            return object;
        });
        const newState = { comments };
        if (typeof this.state.existedCommentsCount === 'undefined') {
          const existedCommentsCount = comments.length;
          newState.existedCommentsCount = existedCommentsCount;
        }

        this.setState(newState, this.handleHeight);
      })
  }

  handleHeight = () => {
    const { firstScroll } = this.state;
    const { scrollTop, offsetHeight, scrollHeight } = this.comments;
    const scrollBottom = scrollHeight - (scrollTop + offsetHeight);
    if (firstScroll || scrollBottom < offsetHeight) {
      setTimeout(this.scrollBottom, 100)
      firstScroll && this.setState({ firstScroll: false });
    }
  }

  scrollBottom = () => {
    if (this.comments) {
      this.comments.scrollTop = this.comments.scrollHeight;
    }
  }

  render() {
    const { comments, existedCommentsCount } = this.state;

    return (
      <section
        className="board-comments"
        ref={node => this.comments = node}
      >
        <ul>
          {comments.map((comment, index) => (
            <li
              key={comment.id}
              className={ index >= existedCommentsCount ? 'added' : ''}
            >
              <CommentCard
                comment={comment}
                index={comment.index}
                // show={start < virtualIndex && virtualIndex < end}
                // admin={admin}
              />
            </li>
          ))}
        </ul>
      </section>

    );
  }
}

export default Comments;
