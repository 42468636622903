import { authNLP, detectKeyword } from './nlp';
// import { authTranslation, translate } from './translateCotoha';
import { googleTranslate } from './translateGoogle';
import { searchImageGoogle, getImagesGoogle } from './googleSearch';
import {
  setResultsNLPToParagraph,
  // setResultsTranslationToParagraph,
  setResultsGoogleTranslationToParagraph,
  setImageGoogleToParagraph,
  setResultsNLP,
  // setResultsTranslation,
  setImageGoogle,
  clearImage,
  preparedKeywords,
} from './firestore';
import { eventWordsDict } from '../assets/eventWords'

const maxKeywordsNum = 5;

export const analyzeSpeech = (paragraphId, text, shouldTranslate = true) => {
  if (!paragraphId) {
    console.error('paragraphId is undefined');
    return;
  } else if (text) {

    // イベント対応
    const detectedEventWords = detectEventWords(text)
    if (detectedEventWords && detectedEventWords.length) {
      setResultsNLPToParagraph(paragraphId, detectedEventWords);
      const keyword = getImportant(detectedEventWords);
      getImagesGoogle(keyword).then(items => {
        const eventImages = eventWordsDict[keyword];
        setImageGoogleToParagraph(paragraphId, keyword, items.map((item, index) => {
          const eventImage = eventImages[index];
          if (!eventImage) {
            return item;
          }
          const eventItem = { ...item };
          eventItem.link = eventImage;
          eventItem.tilte = keyword;
          return eventItem;
        }));
      });
    } else {
      authNLP().then(token => detectKeyword(token, text, maxKeywordsNum).then(res => {
        if (res && res.result) {
          const keywords = filterKeywords(res.result);
          setResultsNLPToParagraph(paragraphId, keywords);
          if (keywords.length) {
            const keyword = getImportant(keywords);
            getImagesGoogle(keyword).then(items => setImageGoogleToParagraph(paragraphId, keyword, items));
          } else {
            clearImage(paragraphId);
          }
        }
      }));

    }


    if (shouldTranslate) {
      // COTOHA translate
      // authTranslation().then(token => translate(token, text).then(res => setResultsTranslationToParagraph(paragraphId, res)))
      //   .catch(error => console.log(error));

      // Google translate
      googleTranslate(text).then(res => setResultsGoogleTranslationToParagraph(paragraphId, res))
          .catch(error => console.log(error));
    }
  }
}

const filterKeywords = keywords => {
  return keywords.map(item => {
    if (item.form === '私') {
      return false;
    }
    if (item.form === '全員') {
      return false;
    }
    if (item.form === '我々') {
      return false;
    }
    if (item.form === 'なし') {
      return false;
    }
    if (item.form === 'めり') {
      return false;
    }
    return item;
  })
  .filter(Boolean);
}

export const getImportant = keywords => {
  let keyword = keywords[0].form;
  let score = keywords[0].score;

  if (keywords.length > 1) {
    keywords.map(item => {
      const tmpScore = item.score;
      if (tmpScore > score) {
        keyword = item.form;
        score = tmpScore;
      }
      return item;
    });
  }
  return keyword;
}

// イベント対応
const detectEventWords = text => {
  const eventWords = Object.keys(eventWordsDict);
  const results = [];
  eventWords.map(word => {
    if (text.indexOf(word) > -1) {
      results.push(word);
    }
    return word;
  })

  return results.map(word => ({ form: word, score: 100 }))
}

////////////////////////////////////////////////////////////
// deprecated
////////////////////////////////////////////////////////////

export const analyzeText = (boardName, id, text) => {
  if (text) {
    const detectedWords = detectPreparedWords(text);
    if (detectedWords.length) {
      const keywords = convertKeywordObject(detectedWords);
      setResultsNLP(boardName, id, keywords);
      const {
        // amanaImageId,
        word,
      } = detectedWords[0];
      // const maxNumber = 1;
      // searchImage(amanaImageId, maxNumber).then(res => setImage(boardName, id, word, res)); // Amana
      searchImageGoogle(word).then(res => setImageGoogle(boardName, id, word, res));

    } else {
      authNLP().then(token => detectKeyword(token, text, maxKeywordsNum).then(res => {
        if (res && res.result) {
          const keywords = filterKeywords(res.result);
          setResultsNLP(boardName, id, keywords);
          if (keywords.length) {
            const keyword = getImportant(keywords);
            // const maxNumber = 1;
            // searchImage(keyword, maxNumber).then(res => setImage(boardName, id, keyword, res));
            searchImageGoogle(keyword).then(res => setImageGoogle(boardName, id, keyword, res));
          }
        }
      }));
    }

    // authTranslation().then(token => translate(token, text).then(res => setResultsTranslation(boardName, id, res)));
  }
}

const detectPreparedWords = text => {
  const results = [];
  const words = Object.keys(preparedKeywords);
  words.map(word => {
    if (~text.indexOf(word)) {
      // result = preparedKeywords[word].amanaImageId;
      results.push(preparedKeywords[word]);
    }
    return word;
  })

  return results;
}

const convertKeywordObject = words => {
  return words.map(word => {
    return { form: word.word, score: 100 };
  }
)};
