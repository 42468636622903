import React, { Component } from 'react';

import {
  createComment,
  createCommentAsGuest,
} from '../../lib/firestore';
import { guestUserId } from '../../assets/constants';
import defaultUserIcon from '../../assets/images/thum_user.svg';
import defaultGuestIcon from '../../assets/images/thum_user_guest.svg';

class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guestName: 'ゲスト',
    };
  }

  handleText = e => this.setState({ comment: e.target.value });

  postComment = event => {
    event && event.preventDefault();
    event && event.stopPropagation();

    const comment = this.form.innerText.trim();
    if (!comment) {
      return;
    }
    const { boardId, loginUser } = this.props;
    if (loginUser && loginUser.id !== guestUserId) {
      createComment(boardId, loginUser.id, comment)
    } else {
      const { guestName } = this.state;
      createCommentAsGuest(boardId, guestName, comment)
    }
    this.form.innerText = '';
  }

  handleKeyPress = event => {
    event && event.stopPropagation();
    if (event.key === 'Enter' && event.shiftKey) {
      event && event.preventDefault();
      this.postComment()
    }
  }

  render() {
    const { loginUser } = this.props;
    const icon = loginUser && loginUser.id !== guestUserId ? (loginUser && loginUser.icon) || defaultUserIcon : defaultGuestIcon;

    return (
      <aside className="board-comments-submit">
        <form className="media" onSubmit={this.postComment} >
          <div className="media-left is-hidden-desktop">
            <figure className="image icon-prof is-32x32">
              <img className="image is-32x32 is-rounded" src={icon} alt="user icon" />
            </figure>
          </div>
          <div className="media-content">
            <div className="field has-addons">
              <div className="control" style={{ flex: 1 }}>
                <div ref={node => this.form = node} className="textarea" role="textbox" contentEditable data-placeholder="コメントを記入" onKeyPress={this.handleKeyPress} />
              </div>
              <nav className="control">
                  <button
                    title="送信"
                    className="button is-success"
                    onClick={this.postComment}
                  >
                    <span className="is-hidden-mobile is-inline-tablet has-text-weight-bold">送信</span>
                    <span className="is-inline-block-mobile is-hidden-tablet"><i className="fa fa-paper-plane" /></span>
                  </button>
              </nav>
            </div>
          </div>
        </form >
      </aside>
    );
  }
}

export default CommentForm;
