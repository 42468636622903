import React, { Component } from 'react';

import { convertHttps } from '../../lib/amanaImages';

class ResultCard extends Component {
  state = {
    classNameFigure: '',
    sectionHeight: null,
    // textTags: [],
    even: false,
  }

  componentDidMount() {
    this.isEven();
  }

  handleImage = () => {
    this.addClassNameFigure();
    this.fixSectionHeight();
  }

  addClassNameFigure = () => this.setState({ classNameFigure: 'append' });

  fixSectionHeight = () => {
    if (this.image) {
      const height = this.image.offsetHeight;
      this.setState({ sectionHeight: height });
    }
  }

  isEven = () =>  {
    const even =  this.props.index % 2 === 0
    this.setState({ even });
  }

  handleText = (text, imageSearchWord ) => {
    // const { text, imageSearchWord } = this.props.speech;
    if (text) {
      let keywords;
      if (imageSearchWord) {
        keywords = [{ form: imageSearchWord }];
      }
      const splittedText = this.splitKeyword(text, keywords);
      const textTags = this.createTag(splittedText);
      return textTags;
    }
  }

  splitKeyword = (text, keywords) => {
    const keywordTag = '<keyword>';
    keywords && keywords.map((item, index) => {
      const keyword = item.form;
      const regExp = new RegExp(keyword, 'g');
      text = text.replace(regExp, keywordTag + keyword + keywordTag)

      // if (this.detectHalf(keyword)) {
      //   const zenkaku = this.changeToZenkaku(keyword);
      //   console.log('moto', keyword, 'zenaku',zenkaku);
      //   const zenkakuRegExp = new RegExp(zenkaku, 'g');
      //   text = text.replace(zenkakuRegExp, keywordTag + zenkaku + keywordTag)
      // }
      //
      // const NG = 'ict';
      //
      // if (keyword === NG) {
      //   console.log('ng')
      //   const zenakuNG = 'ｉｃｔ';
      //   const ngRegExp = new RegExp(zenakuNG, 'g');
      //   text = text.replace(ngRegExp, keywordTag + NG + keywordTag);
      // }
      return item;
    });
    return text.split(keywordTag)
  }

  // detectHalf = str => {
  //   let result = false;
  //   if(str.match(/^[A-Za-z0-9]*$/)){
  //     result = true;
  //   }else{
  //     result = false;
  //   }
  //   return result;
  // }
  //
  // changeToZenkaku = str => {
  //   // return str.replace(/[A-Za-z0-9]/g, s => {
  //   //   return String.fromCharCode(s.charCodeAt(0) + 65248);
  //   // });
  //   return str.replace(/./g, s => {
  //    return String.fromCharCode(s.charCodeAt(0) + 0xfee0)
  //  })
  // }

  createTag = array => {
    const keywords = this.props.speech.keywords || [];
    return array.map((text, index)=> {
      let tag = text;
      if (keywords.map(item => item.form).indexOf(text) >= 0) {
        tag = <span key={index} className="keyword">{text}</span>
      }
      return tag;
    })
  }

  render() {
    const { speech, show } = this.props;

    if (!show) {
      return null;
    }

    const { text, imageSearchWord, translated, images, isTimestamp, createdAt } = speech;

    // // 日本語と英語非表示時に画像がないコンテンツをはじく
    // if (!this.props.japanese && !this.props.english && !(images && images.length)) {
    //   return <section className="empty" />
    //   // return <section className={this.state.even ? 'even' : ''} />
    // }

    if (isTimestamp && createdAt) {
      return (
        <time>{createdAt.toDate().toLocaleString()}</time>
      );
    }

    const japanese = (
      <div className={`text-block jp ${this.props.japanese ? '' : 'deactive'}`}>
        <p>
        {this.handleText(text, imageSearchWord,)}
        </p>
      </div>
    );

    const english = translated ? (
      <div className={`text-block en ${this.props.english ? '' : 'deactive'}`}>
        <p>{translated}</p>
      </div>
    ) : null;

    const image = images && images.length ? (
      <div className="img-block" ref={node => this.image = node}>
        <figure className={this.state.classNameFigure} >
          <img
            // src={images[0].url}
            src={convertHttps(images[0].url)}
            alt={images[0].title}
            onLoad={this.handleImage}
          />
        </figure>
      </div>
    ) : null;

    const sectionStyle = { minHeight: this.state.sectionHeight };

    return (
      <section
        className={`${this.state.even ? 'even' : ''}${!(images && images.length) ? ' empty' : ''}`}
        style={sectionStyle}
      >
        {japanese}
        {english}
        {image}
      </section>
    );
  }
}

export default ResultCard;
