import { connect } from 'react-redux';

import * as app from '../modules/app';
import Modal from '../components/Modal/Modal';

const mapStateToProps = state => {
  const {
    modal,
  } = state.app;

  return {
    modal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showModalRegister: () => dispatch(app.showModalRegister()),
    showModaLogin: () => dispatch(app.showModaLogin()),
    hideModal: () => dispatch(app.hideModal()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modal);
