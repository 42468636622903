import React from 'react';

const DeleteDialog = props => {
  const { open, handleDelete, handleClose, boardTitle } = props;
  if (!open) {
    return null;
  }

  const onDelete = () => {
    handleDelete && handleDelete();
    handleClose && handleClose();
  }

  return (
    <div className="modal modal-delete is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <h5 className="modal-card-title">ボードの削除</h5>
          <button className="delete" aria-label="close" onClick={handleClose} ></button>
        </header>
        <section className="modal-card-body">
          <p style={{marginBottom: 1 + 'rem'}}><span className="name is-size-6 has-text-weight-semibold">{`「 ${boardTitle}」`}</span>を削除します。</p>
          {`一度削除すると復元不可能になります。本当に削除してもよろしいですか?`}
        </section>
        <footer className="modal-card-foot">
          <button className="button is-danger" onClick={onDelete} >削除</button>
          <button className="button" onClick={handleClose} >キャンセル</button>
        </footer>
      </div>
    </div>
  );
};

export default DeleteDialog;
