import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

class AlertDialog extends React.Component {
  state = {
    // open: this.props.open,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
    // this.setState({ open: false });
  };

  render() {
    const cancel = this.props.cancel ? (
      <Button onClick={this.props.onCancel || this.handleClose} color="primary" autoFocus>
        {this.props.cancelButtonTitle ? this.props.cancelButtonTitle : 'Cancel'}
      </Button>
    ) : null;
    const { text } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{text}</DialogTitle>
        <DialogActions>
          {cancel}
          <Button onClick={this.props.onOk || this.handleClose} color="primary" autoFocus>
            {this.props.okButtonTitle ? this.props.okButtonTitle : 'OK'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AlertDialog;
