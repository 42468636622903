import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { addHeightResizeListener, removeHeightResizeListener } from '../../lib/scroll';
import logo from '../../assets/images/logo.svg';
import ResultGuest from '../ResultGuest/ResultGuest';
import TextAlert from '../TextAlert/TextAlert';

class GuestPage extends Component {
  state = {
    speeches: [],
    japanese: true,
    english: true,
    live: undefined,
    start: -1,
    end: 15,
    limit: 75,
    alertText: 'プレゼンテーションが開始されました。Liveモードに切り替えますか？',
    alert: false,
  }

  componentDidMount() {
    const mobile = window.innerWidth < 500;

    this.unsubscribeContents = this.subscribeDB();
    this.unsubscribeState = this.fetchState();
    window.onscroll = this.onScroll;
    if (mobile) {
      window.touchmove = this.onScroll;
    }
    addHeightResizeListener(this.container, this.stickBottom);
  }

  componentWillUnmount() {
    this.unsubscribeContents();
    this.unsubscribeState();
    removeHeightResizeListener(this.container);
  }

  // ライブ中に画面最下部にスクロールしている場合は画面最下部に固定
  stickBottom = () => {
    const { scrollTop, offsetHeight, scrollHeight } = document.documentElement;
    const scrollBottom = scrollHeight - (scrollTop + offsetHeight);
    if (this.state.live && scrollBottom < window.innerHeight * 0.8) {
      setTimeout(this.scrollBottom, 100)
    }
  }

  onScroll = () => {
    const mobile = window.innerWidth < 500;

    const { live } = this.state;
    const { offsetHeight, scrollHeight } = document.documentElement;

    const scrollTop = !mobile ? document.documentElement.scrollTop : window.scrollY;
    const scrollBottom = scrollHeight - (scrollTop + offsetHeight);

    const top = scrollTop < window.innerHeight * 0.2;
    const bottom = scrollBottom < window.innerHeight * 0.2;

    if (live && scrollTop < window.innerHeight * 0.2) {
      this.nextContents();
    } else if (!live && scrollBottom < window.innerHeight * 0.2) {
      this.nextContents();
    }

    if (live) {
       if (top) {
         this.nextContents();
       } else if (bottom) {
         this.backContents()
       }
    } else {
      if (top) {
        this.backContents();
      } else if (bottom) {
        this.nextContents()
      }
    }
  }

  showAlert = () => this.setState({ alert: true });

  handleOk = () => {
    this.setLiveMode(true);
    this.setState({ alert: false });
  }

  handleClose = () => this.setState({ alert: false });

  nextContents = () => {
    const { speeches, limit } = this.state;
    let { start, end } = this.state;
    const contentsLength = end - start;
    if (end < speeches.length) {
      end += 10;
      if (contentsLength > limit) {
        start = end - limit
      }

      this.setState({ start, end });
    }
  }

  backContents = () => {
    const { limit } = this.state;
    let { start, end } = this.state;
    if (start > -1) {
      start -= 10;
      if (start < 10) {
        start = -1
      }
      end = start + limit

      this.setState({ start, end });
    }
  }

  fetchState = () => {
    const db = firebase.firestore();
    const docId = this.props.boardName;
    return db.collection('states').doc(docId)
      .onSnapshot(doc => {
        const live = doc.data().live;
        if (typeof this.state.live === 'undefined') {
          this.setLiveMode(live);
        }
        if (live && !this.state.live) {
          this.showAlert();
        }
      })
  }

  setLiveMode = live => {
    if (live !== this.state.live) {
      this.setState({ live, start: -1, end: 15 }, () => setTimeout(this.scrollBottom, 200));
    }
  }

  subscribeDB = () => {
    const db = firebase.firestore();
    const subDir = this.props.boardName;
    const path = `speeches/${subDir}/items`;
    return db.collection(path).orderBy('createdAt')
      .onSnapshot(querySnapshot => {
        const speeches = querySnapshot.docs.map((doc, index) => {
            const { id } = doc;
            const object = {
              ...doc.data(),
              id,
              index,
            }
            return object;
        });
        this.setState({ speeches });
      })
  }

  scrollBottom = () => {
    const container = this.container;
    if (container && this.state.live) {
      window.scrollTo({left: 0, top: container.scrollHeight, behavior: 'smooth'});
    }
  };

  toggleJapanese = () => this.setState({ japanese: !this.state.japanese })

  toggleEnglish = () => this.setState({ english: !this.state.english })

  render() {
    const { speeches, japanese, english, start, end, live } = this.state;

    const results = speeches.map((speech, index) => {
      const virtualIndex = live ? speeches.length - index - 1 : index;
      return (
        <ResultGuest
          speech={speech}
          key={speech.id}
          index={speech.index}
          japanese={japanese}
          english={english}
          show={start < virtualIndex && virtualIndex < end}
        />
      );
    })

    return (
      <div
        id="container"
        ref={node => this.container = node}
      >
      <header>
        <h1><a href="#container"><img src={logo} alt="logo" /></a></h1>
        <nav>
          <ul>
            <li>
              <button
                className={!this.state.japanese ? 'roboto deactive' : 'roboto'}
                onClick={this.toggleJapanese}
              >
                JP
              </button>
            </li>
            <li>
              <button
                className={!this.state.english ? 'roboto deactive' : 'roboto'}
                onClick={this.toggleEnglish}
              >
                EN
              </button>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <article>
          {results}
        </article>
        <TextAlert
          open={this.state.alert}
          onClose={this.handleClose}
          onOk={this.handleOk}
          text={this.state.alertText}
          cancel
          onCancel={this.handleClose}
          okButtonTitle="Yes"
          cancelButtonTitle="No"
        />
      </main>
    </div>
    );
  }
}

export default GuestPage;
