import { connect } from 'react-redux';

import * as app from '../modules/app';
import App from '../components/App';

const mapDispatchToProps = dispatch => {
  return {
    setLoginUser: user => dispatch(app.setLoginUser(user)),
    setGuestUser: () => dispatch(app.setGuestUser())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(App);
