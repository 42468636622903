import React from 'react';

import ModalRegister from './ModalRegister';
import ModalLogin from './ModalLogin';
import ModalDelete from './ModalDelete';

const Modal = props => {
  const { modal, hideModal, showModalRegister, showModaLogin } = props;

  return (
    <React.Fragment>
      <ModalRegister show={modal === 'register'} handleClose={hideModal} switchTab={showModaLogin} />
      <ModalLogin show={modal === 'login'} handleClose={hideModal} switchTab={showModalRegister} />
      <ModalDelete show={modal === 'delete'}  handleClose={hideModal} />
    </React.Fragment>
  );
}


export default Modal;
