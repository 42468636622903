import { googleCloudKey } from '../assets/constants';

export const googleTranslate = text => {
  const endpoint = 'https://translation.googleapis.com/language/translate/v2';
  const reqUrl = `${endpoint}?key=${googleCloudKey}`;
  const body = {
    q: text,
    format: 'text',
    source: 'ja',
    target: 'en',
    model: 'nmt'
  }

  return fetch(reqUrl, {
    method: "POST",
    mode: "cors",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify(body),
  })
  .then(res => res.json())
  .catch(error => console.log('Error:', error));
}
