import React, { Component } from 'react';
import { withRouter } from 'react-router';

import defaultUserIcon from '../../assets/images/thum_user.svg';

class UserCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    const { user } = this.props;
    if (!user) {
      return null;
    }

    const { name, icon }  = user;

    return (
      <div className="media">
          <div className="media-left">
            <figure
              className="image icon-prof is-64x64"
            >
              <img className="image is-64x64 is-rounded" src={icon || defaultUserIcon} alt="icon" />
            </figure>
          </div>
          <div className="media-content">
            <div className="content">
                <h1 className="title is-size-5-mobile is-size-4-tablet">
                  {name}
                </h1>
                <p className="subtitle is-6">
                  <a href={`${process.env.PUBLIC_URL}/dashboard/setting`}>
                    <span><i className="fas fa-cog"></i></span>
                    <span> プロフィール編集</span>
                  </a>
                </p>
            </div>
          </div>
      </div>
    );
  }
}

export default withRouter(UserCard);
