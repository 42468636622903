import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { signUp } from '../../lib/firestore';
import { firebaseErrorResponses } from '../../assets/constants';

class ModalRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      pass: '',
      passConfirm: '',
      // invalidEmail: false,
      errorMessage: '',
      agreed: false,
      loading: false,
    };
  }

  handleName = event => this.setState({ name: event.target.value })

  handleEmail = event => this.setState({ email: event.target.value })

  handlePass = event => this.setState({ pass: event.target.value })

  handlePassConfirm = event => this.setState({ passConfirm: event.target.value })

  validatePassConfirm = () => {
    const { pass, passConfirm } = this.state;
    if (pass && passConfirm && pass === passConfirm) {
      return true
    } else {
      this.setState({
        errorMessage: 'パスワードを確認してください。',
      });
      return false;
    }
  }

  handleSignUp = async event => {
    event && event.preventDefault();
    event && event.stopPropagation();
    const { email, pass, name, agreed } = this.state;

    if (!name) {
      this.setState({
        errorMessage: '名前を入力してください。',
      });
      return;
    }

    if (!this.validatePassConfirm()) {
      return;
    }

    if (!agreed) {
      this.setState({
        errorMessage: '利用規約に同意する必要があります。',
      });
      return;
    }

    this.setState({ loading: true });
    const res = await signUp(email, pass, name);
    const { errorCode } = res;
    if (!errorCode) {
      const callback = () => {
        const { handleClose } = this.props;
        handleClose && handleClose();
        const stayAfterLogin = Boolean(~window.location.pathname.indexOf('/board'));
        !stayAfterLogin && this.openDashboard();
      }
      this.clearState(callback)
    } else {
      let errorMessage = firebaseErrorResponses[errorCode];
      if (!errorMessage) {
        errorMessage = firebaseErrorResponses['fallback-signup'];
      }
      this.setState({ errorMessage, loading: false });
    }
  }

  clearState = callback => {
    this.setState({
      name: '',
      email: '',
      pass: '',
      passConfirm: '',
      agreed: false,
      invalidEmail: false,
      errorMessage: '',
      loading: false,
    }, () => callback && callback())
  }

  handleClose = event => {
    event && event.preventDefault();
    event && event.stopPropagation();
    const { handleClose } = this.props;
    this.clearState(handleClose)
  }

  handleSwitch = () => this.clearState(this.props.switchTab)

  toggleAgreed = event => this.setState({ agreed: !this.state.agreed });

  openDashboard = () => this.props.history.push({ pathname: `${process.env.PUBLIC_URL}/dashboard` })

  render() {
    const { name, email, pass, passConfirm, invalidEmail, errorMessage, agreed, loading } = this.state;
    const { show } = this.props;

    return (
      <div className={`modal${ show ? ' is-active' : ''}`} id="ModalRegister">
          <div className="modal-background"></div>
          <div className="modal-card">
            <section className="modal-card-body">
                <button
                  onClick={this.handleClose}
                  className="delete is-pulled-right"
                  aria-label="close"
                />
                <section className="section">
                    <div className="tabs is-rounded is-centered">
                        <ul>
                          <li className="is-active">
                            {
                              // eslint-disable-next-line
                              <a className="button is-rounded">新規登録</a>
                            }
                          </li>
                          <li>
                            {
                              // eslint-disable-next-line
                              <a className="button is-rounded" onClick={this.handleSwitch} >ログイン</a>
                            }
                          </li>
                        </ul>
                    </div>
                    <form onSubmit={this.handleSubmit} >
                        <div className="field">
                          <label className="label">お名前</label>
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              placeholder="お名前"
                              value={name}
                              onChange={this.handleName}
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Email</label>
                          <div className="control has-icons-left">
                            <input
                              className={`input${invalidEmail ? ' is-danger' : ''}`}
                              type="email"
                              placeholder="Email"
                              value={email}
                              onChange={this.handleEmail}
                            />
                            <span className="icon is-small is-left">
                              <i className="fas fa-envelope"/>
                            </span>
                          </div>
                          {invalidEmail && <p className="help is-danger">Emailアドレスが正しくありません。</p>}
                        </div>

                        <div className="field">
                            <label className="label">パスワード</label>
                            <div className="control">
                              <input
                                className="input"
                                type="password"
                                value={pass}
                                onChange={this.handlePass}
                              />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">パスワード確認</label>
                            <div className="control">
                              <input
                                className="input"
                                type="password"
                                value={passConfirm}
                                onChange={this.handlePassConfirm}
                              />
                            </div>
                        </div>

                        <div className="field">
                          <div className="level">
                            <div className="level-left">
                              <label
                                className="checkbox is-size-7"
                              >
                                <input
                                  type="checkbox"
                                  checked={agreed}
                                  onChange={this.toggleAgreed}
                                />
                                  利用規約に同意します
                              </label>
                            </div>
                            <p className="level-right is-size-7">
                              <a
                                href={`${process.env.PUBLIC_URL}/terms`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >利用規約</a>
                              /
                              <a
                                href="https://www.ntt.com/about-us/hp/privacy.html"
                                target="_blank"
                                rel="noopener noreferrer"
                              >プライバシーポリシー</a>
                            </p>
                          </div>
                        </div>

                        {errorMessage && <p className="help is-danger">{errorMessage}</p>}
                        <div className="field columns is-half" style={{ margin: '1rem 0'}}>
                          <div className="control column is-half">
                            <button
                              onClick={this.handleClose}
                              className="button is-fullwidth"
                            >
                              キャンセル
                            </button>
                          </div>
                          <div className="control column">
                            <button
                              onClick={this.handleSignUp}
                              className={`button is-fullwidth is-success${loading ? ' is-loading' : ''}`}
                            >
                              ユーザー登録
                            </button>
                          </div>
                        </div>

                      </form>
                </section>
            </section>
          </div>
        </div>
    );
  }
}

export default withRouter(ModalRegister);
