const queue = [];
let watching = true;

export function enqueue(task) {
  queue.push(task);
}

// export function stop() {
//   watching = false;
//   queue.length = 0;
// }

function watch() {
  if (!watching) {
    return;
  }

  return new Promise(function (resolve, reject) {
    setTimeout(async () => {
      if (queue.length) {
        const task = queue.shift();
        if (typeof task === 'function') {
          await task();
        } else {
          console.log('this task is not function')
        }
      }
      resolve();
    }, 50);
  }).then(watch)
}

watch();
