import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';

const styles = theme => ({
  icon: {
    position: 'fixed',
    zIndex: 1000,
  },
});

const RecordButton = props => {
  const { classes, active, onClick, height } = props;
  const icon = !active ? <Mic /> : <MicOff />;
  const color = !active ? 'primary' : 'secondary';
  const className = active ? `${classes.icon} animation-bounce` : classes.icon;

  const top = height ? window.innerHeight - height : window.innerHeight - 180;
  const right = window.innerWidth > 500 ? 60 : 44;

  const style = { top, right };

  return (
    <Fab
      color={color}
      aria-label="Edit"
      className={className}
      onClick={onClick}
      size="large"
      style={style}
    >
      {icon}
    </Fab>
  );
}

RecordButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecordButton);
