export const searchImage = (word, num) => {
  // const endpoint = `http://api02.amanaimages.com/api/demo/searchImage.aspx?code=YiGbg759Vd&keyword=${word}&limit=${num}`;
  const endpoint = `https://subz-app-server.herokuapp.com/api/v1/images?word=${word}&num=${num}`;

  return fetch(endpoint, {
      method: "GET",
      mode: "cors",
      headers: {
          "Content-Type": "application/json"
      },
  })
  .then(res => res.json())
  .catch(error => console.error('Error:', error));
}

export const getImageById = id => {
  const endpoint = `http://api02.amanaimages.com/api/demo/HDimageDownload.aspx?code=YiGbg759Vd&passcode=0a99fb71c06097df9fbb952fc3497146&imageid=${id}`;
  // const endpoint = `http://api02.amanaimages.com/api/demo/HDimageDownload.aspx?code=YiGbg759Vd&passcode=0a99fb71c06097df9fbb952fc3497146&imageid=10568004436`;
  return fetch(endpoint, {
      method: "GET",
      mode: "cors",
      headers: {
          "Content-Type": "application/json",
      },
  })
  .then(res => {
    console.log(res)
    // res.json()
    return res
  })
  .catch(error => console.error('Error:', error));
}

export const convertHttps = url =>  {
  const http = 'http://'
  const https = 'https://'
  const regExp = new RegExp(http, 'g');
  return url.replace(regExp, https)
}
