import { connect } from 'react-redux';

// import * as app from '../modules/app';
import CommentForm from '../components/Board/CommentForm';

const mapStateToProps = state => {
  const {
    loginUser,
  } = state.app;

  return {
    loginUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(CommentForm);
