import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import cyan from '@material-ui/core/colors/cyan';
import green from '@material-ui/core/colors/green';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 60,
    marginBottom: 36,
  },
  margin: {
    margin: theme.spacing.unit,
    minWidth: 600,
    maxWidth: 800,
  },
  cssLabel: {
    // color: 'white',
    color: cyan[500],
    borderBottomColor: 'white',
    '&$cssFocused': {
      color: cyan[500],
    },
  },
  cssFocused: {},
  cssUnderline: {
    borderBottomColor: 'white',
    '&:after': {
      borderBottomColor: cyan[500],
    },
  },
});

// eslint-disable-next-line
const theme = createMuiTheme({
  palette: {
    primary: green,
  },
  typography: { useNextVariants: true },
});

const CustomizedInputs = props => {
  const { classes, handleText, value } = props;

  return (
    <div className={classes.root}>
      <FormControl className={classes.margin}>
        <InputLabel
          htmlFor="custom-css-standard-input"
          classes={{
            root: classes.cssLabel,
            focused: classes.cssFocused,
          }}
        >
          Input Text
        </InputLabel>
        <Input
          id="custom-css-standard-input"
          classes={{
            underline: classes.cssUnderline,
          }}
          style={{ color: 'white' }}
          multiline
          value={value}
          onChange={handleText}
        />
      </FormControl>
    </div>
  );
}

CustomizedInputs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedInputs);
