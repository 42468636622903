import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Delete from '@material-ui/icons/DeleteForever';

const styles = theme => ({
  icon: {
    position: 'fixed',
    right: 60,
    top: window.innerHeight - 100,

  },
});

const DeleteButton = props => {
  const { classes, onClick } = props;
  const color = 'secondary';
  return (
    <Fab color={color} aria-label="Edit" className={classes.icon} onClick={onClick} size="large" >
      <Delete />
    </Fab>
  );
}

DeleteButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeleteButton);
