import React, { Component } from 'react';
import { withRouter } from 'react-router';

import Footer from './Footer';
import HeaderContainer from '../containers/HeaderContainer';
// import logoBk from '../../assets/images/logo-bk-desc.svg';
import screenshot from '../assets/images/screenshot.png';
import logoBkText from '../assets/images/logo-bk-text.svg';
import pict1 from '../assets/images/feature_pict_1.svg';
import pict2 from '../assets/images/feature_pict_2.svg';
import pict3 from '../assets/images/feature_pict_3.svg';
import pict4 from '../assets/images/feature_pict_4.svg';
import pict5 from '../assets/images/feature_pict_5.svg';
import pict6 from '../assets/images/feature_pict_6.svg';
import logoKonel from '../assets/images/logo-konel.svg';
import {
  createBoard,
} from '../lib/firestore';
import { guestUserId } from '../assets/constants';

class Top extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleCreate = async () => {
    this.setState({ loading: true });
    const { loginUser } = this.props;
    let boardId;
    if (loginUser && loginUser.id !== guestUserId) {
      boardId = await createBoard(loginUser.id);
    } else {
      boardId = await createBoard();
    }
    if (boardId) {
      this.openBoard(boardId);
    }
    this.setState({ loading: false });
  }

  openBoard = boardId => this.props.history.push({ pathname: `${process.env.PUBLIC_URL}/alpha/board/${boardId}` });

  render() {
    const { loading } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer />
        <div className="content-wrapper">
          <div className="content-insede ">
            <section className="section hero hero-top is-dark">
              <div className="container">
                <div className="columns has-text-centered is-vcentered">
                  <div className="column is-half">
                    <h1 className="logo-large title"><img src={logoBkText} alt="Subz." /></h1>
                    <p className="has-text-weight-semibold" style={{marginBottom: 1.5 + 'rem'}}>Subz.はプレゼンテーションの即時ビジュアライズを実現する<span className="is-inline-block">音声アプリケーションです。</span></p>
                      {
                        // eslint-disable-next-line
                        <a id="button-demo" className={`button is-link is-large${loading ? ' is-loading' : ''}`} onClick={this.handleCreate} >
                            <span className="roboto">TRY DEMO</span> <span className="is-size-6">デモを試してみる<i className="fas fa-arrow-alt-circle-right"></i></span>
                        </a>
                      }
                  </div>
                  <div className="column is-half">
                    <img src={screenshot} alt="ss" id="topSs" />
                  </div>
                </div>
              </div>
            </section>

            <section className="section section-wide features">
                <div className="container">
                  <h2 className="is-size-4-tablet is-size-5-mobile has-text-centered has-text-weight-bold">現段階のプロトタイプは<span className="is-inline-block">以下の機能を実装しています</span></h2>
                  <ul className="columns is-mobile is-multiline has-text-centered">
                    <li className="column is-half-mobile is-one-third-tablet">
                      <figure className="pict"><img src={pict1} alt="" /></figure>
                      <p className="is-size-7-mobile">日本語 / 英語（翻訳）の<span className="br">リアルタイム字幕表示</span></p>
                    </li>
                    <li className="column is-half-mobile is-one-third-tablet">
                        <figure className="pict"><img src={pict2} alt=""/></figure>
                        <p className="is-size-7-mobile">重要キーワードに関連する<span className="br">画像表示</span></p>
                    </li>
                    <li className="column is-half-mobile is-one-third-tablet">
                        <figure className="pict"><img src={pict3} alt=""/></figure>
                        <p className="is-size-7-mobile">スマートフォンでの<span className="br">記事型ビュー</span></p>
                    </li>
                    <li className="column is-half-mobile is-one-third-tablet">
                      <figure className="pict"><img src={pict4} alt=""/></figure>
                      <p className="is-size-7-mobile">ボード<sup>*</sup> の作成・保存</p>
                    </li>
                    <li className="column is-half-mobile is-one-third-tablet">
                      <figure className="pict"><img src={pict5} alt=""/></figure>
                      <p className="is-size-7-mobile">オーディエンスの<span className="br">リアルタイムコメント</span></p>
                    </li>
                    <li className="column is-half-mobile is-one-third-tablet">
                      <figure className="pict"><img src={pict6} alt=""/></figure>
                      <p className="is-size-7-mobile">気になるボード<sup>*</sup> の<span className="br">ピン</span></p>
                    </li>
                  </ul>
                </div>
                <p className="has-text-centered is-size-7">＊ プレゼンテーションごとの固有ページ</p>
                <p className="has-text-centered is-size-7" style={{marginTop: .5 + 'rem'}}>音声認識は Google Chrome(スマートフォンの場合は Safari for iOS)のみ対応しています。</p>
            </section>

            <section className="section section-wide has-text-centered credit">
              <p className="is-6-tablet is-size-7-mobile has-text-weight-semibold">実用化に向けたブラッシュアップを継続中です。<br/>
              トライアルやコラボレーションのご相談は以下までお問い合わせください。</p>
              <nav className="level">
                  <div className="level-item has-text-centered">
                    <dl>
                      <dt className="heading roboto">Contact</dt>
                      <dd className="title is-5"><a href="mailto:subz@konel.jp" className="roboto has-text-black">subz@konel.jp</a></dd>
                    </dl>
                  </div>
                  <div className="level-item has-text-centered">
                    <dl>
                      <dt className="heading roboto">Projected By</dt>
                      <dd className="title is-5"><a href="http://www.ntt.com/business/lp/mirai_biz.html" className="roboto has-text-black" target="_blank" rel="noopener noreferrer">NTT Communications C×4 BASE</a></dd>
                    </dl>
                  </div>
                  <div className="level-item has-text-centered">
                    <dl>
                      <dt className="heading roboto">Developed By</dt>
                      <dd className="title is-5">
                          <a href="http://konel.jp" className="roboto has-text-black" target="_blank" rel="noopener noreferrer"><img src={logoKonel} alt="Konel." /></a>
                      </dd>
                    </dl>
                  </div>
                </nav>
            </section>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(Top);
