import React, { Component } from 'react';
import { withRouter } from 'react-router';

import {
  logOut,
} from '../../lib/firestore';
import { guestUserId } from '../../assets/constants';
import logo from '../../assets/images/logo.svg';
import logoBk from '../../assets/images/logo-bk.svg';
import defaultUserIcon from '../../assets/images/thum_user.svg';
import defaultGuestIcon from '../../assets/images/thum_user_guest.svg';

class Header extends Component {
  state = {
    activeMenu: false,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  handleClick = event =>{
    if (this.headerMenu && this.headerMenu.contains(event.target)) {
      return
    }
    if (this.menuIcon && this.menuIcon.contains(event.target)) {
      return;
    }
    if ((this.headerMenu && !this.headerMenu.contains(event.target))) {
      this.toggleActiveMenu(false);
    }
  }

  toggleActiveMenu = (active = !this.state.activeMenu) => {
    if (active !== this.state.activeMenu) {
      this.setState({ activeMenu: active});
    }
  }

  openDashboard = () => this.props.history.push({ pathname: `${process.env.PUBLIC_URL}/dashboard` })

  openSetting = () => this.props.history.push({ pathname: `${process.env.PUBLIC_URL}/dashboard/setting` })

  render() {
    const { loginUser, dark, showModaLogin, showModalRegister } = this.props;
    const { activeMenu } = this.state;

    const icon = loginUser && loginUser.id !== guestUserId ? loginUser.icon || defaultUserIcon : defaultGuestIcon;

    const menu = loginUser && loginUser.id !== guestUserId ? (
      <div className="navbar-end">
        <div className={`navbar-item has-dropdown${ activeMenu ? ' is-active' : ''}`} ref={node => this.headerMenu = node} >
          {
            // eslint-disable-next-line
            <a className={`navbar-item${ activeMenu ? ' is-active' : ''}`} onClick={()=>this.toggleActiveMenu(!activeMenu)} >
              <div className="media">
                  <div className="media-left">
                    <figure className="image icon-prof is-24x24">
                      <img className="image is-24x24 is-rounded" src={icon} alt="icon" />
                    </figure>
                  </div>
                  <div className="media-content">
                      {` ${loginUser.name} `}
                       <i className="fas fa-angle-down"></i>
                  </div>
              </div>
            </a>
          }        
          <div className="navbar-dropdown is-right">
            {
              // eslint-disable-next-line
              <a className="navbar-item" onClick={this.openDashboard} >
                ダッシュボード
              </a>
            }
            {
              // eslint-disable-next-line
              <a className="navbar-item" onClick={this.openSetting} >
                プロフィール編集
              </a>
            }
            {
              // eslint-disable-next-line
              <a className="navbar-item" onClick={logOut} >
                ログアウト
              </a>
            }
          </div>
        </div>
      </div>
    ) : (
      <div className="navbar-end is-guest">
        {
          // eslint-disable-next-line
          <a
            className="navbar-item"
            data-target="ModalSignin"
            onClick={showModalRegister}
          >
            新規登録
          </a>
        }
        {
          // eslint-disable-next-line
          <a
            id="btnLogin"
            data-target="ModalLogin"
            className="navbar-item"
            onClick={showModaLogin}
          >
            ログイン
          </a>
        }
      </div>
    )

    return (
      <React.Fragment>
        <header className="page-header">
          <nav className="navbar has-bg" role="navigation" aria-label="main navigation">
              <div className="navbar-brand">
                <a className="navbar-item is-logo" href="/">
                  <img src={dark ? logo : logoBk} alt="Subz." width="100" />
                </a>
                {
                  // eslint-disable-next-line
                  <a
                    ref={node => this.menuIcon = node}
                    role="button"
                    className={`navbar-burger burger${ activeMenu ? ' is-active' : ''}`}
                    data-target="navMenu"
                    aria-label="menu"
                    aria-expanded="false"
                    onClick={()=>this.toggleActiveMenu(!activeMenu)}
                  >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                  </a>
                }
              </div>
              <div
                id="navMenu"
                className={`navbar-menu${ activeMenu ? ' is-active' : ''}`}
              >
                {menu}
              </div>
            </nav>
        </header>
      </React.Fragment>
    );
  }
};

export default withRouter(Header);
