import ReactGA from 'react-ga';
import { guestUser } from '../assets/constants';

//action
export const SET_LOGIN_USER = 'SET_LOGIN_USER';
export const SET_MODAL = 'SET_MODAL';

export const setLoginUser = user => ({
  type: SET_LOGIN_USER,
  payload: user,
});

export const setGuestUser = () => ({
  type: SET_LOGIN_USER,
  payload: guestUser,
});

export const showModalRegister = () => ({
  type: SET_MODAL,
  payload: 'register',
});

export const showModaLogin = () => ({
  type: SET_MODAL,
  payload: 'login',
});

export const showModalDelete = () => ({
  type: SET_MODAL,
  payload: 'delete',
});

export const hideModal = () => ({
  type: SET_MODAL,
  payload: null,
});

//reducer
const initialState = {
  loginUser: undefined,
  mordal: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_LOGIN_USER:
      return {
        ...state,
        loginUser: action.payload,
      };
    case SET_MODAL:
      if (action.payload) {
        const page = `/modal/${action.payload}`;
        ReactGA.set({ page });
        ReactGA.pageview(page);
      }
      return {
        ...state,
        modal: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
