import React, { Component } from 'react';
import { withRouter } from 'react-router';

import HeaderContainer from '../../containers/HeaderContainer';
import BoardCard from './BoardCard';
import UserCard from './UserCard';
import Footer from '../Footer';
import {
  createBoard,
  fetchMyBoards,
  fetchRecentViewedBoards,
  fetchClippedBoards,
} from '../../lib/firestore';
import { guestUserId } from '../../assets/constants';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myBoards: [],
      recentBoards: [],
      clippedBoards: [],
      activeTab: 0,
      fetched: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.validateUser();
  }

  componentDidUpdate(prevProps, prevState) {
    this.validateUser();
  }

  validateUser = () => {
    const { loginUser } = this.props;

    if (!loginUser) {
      return;
    }

    if (loginUser.id === guestUserId ) {
      this.openTop();
    } else if (!this.state.fetched) {
      this.setState({ fetched: true }, this.fetchBoards)
    }
  }

  fetchBoards = () => {
    this.fetchMine();
    this.fetchViewed();
    this.fetchClipped();
  }

  fetchMine = () => fetchMyBoards(this.props.loginUser.id).then(myBoards => this.setState({ myBoards }))

  fetchViewed = () => fetchRecentViewedBoards(this.props.loginUser.id).then(recentBoards => this.setState({ recentBoards }))

  fetchClipped = () => fetchClippedBoards(this.props.loginUser.id).then(clippedBoards => this.setState({ clippedBoards }))

  handleCreate = () => {
    const { loginUser } = this.props;
    if (loginUser) {
      this.setState({ loading: true })
      createBoard(loginUser.id).then(boardId => {
        this.openBoard(boardId);
        this.setState({ loading: false });
      });
    }
  }

  switchActiveTab = (tabIndex = 0) => this.setState({ activeTab: tabIndex });

  openTop = () => this.props.history.push({ pathname: `${process.env.PUBLIC_URL}/` });

  openLogin = () => this.props.history.push({ pathname: `${process.env.PUBLIC_URL}/login` });

  openBoard = boardId => this.props.history.push({ pathname: `${process.env.PUBLIC_URL}/alpha/board/${boardId}` });

  render() {
    const { activeTab, myBoards, recentBoards, clippedBoards, loading } = this.state;
    const { loginUser } = this.props;
    return (
      <React.Fragment>
        <HeaderContainer />
        <div className="content-wrapper">
          <div className="content-insede ">
            <section className="section">
              <div className="container">
                <div className="columns">
                  <div className="column">
                    <UserCard user={loginUser} />
                  </div>
                </div>
              </div>
            </section>

            <section className="section has-background-white-ter" style={{ paddingTop: 0 }}>
              <div className="container">
                <div className="level is-mobile">
                <div className="level-left">
                    <h2 className="level-item has-text-weight-bold is-size-6-mobile is-size-4-tablet">ボードリスト</h2>
                    <div className="level-item">
                      {loginUser ? (
                        // eslint-disable-next-line
                        <a className={`button is-success${loading ? ' is-loading' : ''}`} onClick={this.handleCreate}>
                          <span className="icon"><i className="fas fa-plus" /></span>
                          <span>ボードを作成</span>
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="tabs is-rounded">
                    <ul>
                      <li className={activeTab === 0 ? 'is-active' : ''} onClick={() => this.switchActiveTab(0)} >
                        {
                          // eslint-disable-next-line
                          <a className="button is-rounded"><span className="icon is-small"><i className="fas fa-folder" aria-hidden="true"></i></span><span className="is-tab-text">マイボード</span></a>
                        }
                      </li>
                      <li className={activeTab === 1 ? 'is-active' : ''} onClick={() => this.switchActiveTab(1)} >
                        {
                          // eslint-disable-next-line
                          <a className="button is-rounded"><span className="icon is-small"><i className="far fa-clock" aria-hidden="true"></i></span><span className="is-tab-text">最近見た</span></a>
                        }
                      </li>
                      <li className={activeTab === 2 ? 'is-active' : ''} onClick={() => this.switchActiveTab(2)} >
                        {
                          // eslint-disable-next-line
                          <a className="button is-rounded"><span className="icon is-small"><i className="fas fa-thumbtack" aria-hidden="true"></i></span><span className="is-tab-text">ピンした</span></a>
                        }
                      </li>
                    </ul>
                </div>
                {activeTab === 0 && (<div id="myBoard" className="columns is-multiline is-mobile">
                  {myBoards.map(board => <BoardCard key={board.id} board={board} admin loginUser={loginUser} onClip={this.fetchClipped} onDelete={this.fetchBoards} />)}
                </div>)}
                {activeTab === 1 && (<div id="historyBoard" className="columns is-multiline is-mobile">
                  {recentBoards.map(board => <BoardCard key={board.id} board={board} admin={board.createdBy === loginUser.id} loginUser={loginUser} onClip={this.fetchClipped} onDelete={this.fetchBoards} />)}
                </div>)}
                {activeTab === 2 && (<div id="clipedBoard" className="columns is-multiline is-mobile">
                  {clippedBoards.map(board => <BoardCard key={board.id} board={board} admin={board.createdBy === loginUser.id} loginUser={loginUser} onClip={this.fetchClipped} onDelete={this.fetchBoards} />)}
                </div>)}
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(Dashboard);
