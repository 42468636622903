import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

import { updateDocument, deleteDocument } from '../../lib/firestore';
import { analyzeText } from '../../lib/app';
import { convertHttps } from '../../lib/amanaImages';

const styles = theme => ({
  card: {
    minWidth: 275,
    width: '80vw',
    textAlign: 'start',
    marginTop: 32,
    maxWidth: 800,
  },
  title: {
    fontSize: 14,
    marginBottom: 12,
  },
  pos: {
    marginBottom: 12,
  },
  input: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
});

const Keyword = props => <Typography className={props.className} variant="h5" component="h2">{props.keyword}</Typography>;

class ResultCard extends Component {
  constructor(props) {
    super(props);
    const updatedText = props.speech.text;
    this.state = {
      admin: true,
      editing: true,
      updatedText,
    }
  }

  componentWillReceiveProps(nextProps) {
    const { text } = nextProps.speech;
    if (text !== this.state.updatedText) {
      this.setState({ updatedText: text });
    }
  }

  updateResult = () => {
    const { updatedText } = this.state;
    const { speech } = this.props;
    const { id, text } = speech;
    if (updatedText === text) { return }
    const doc = { text: updatedText };

    updateDocument(this.props.boardName, id, doc);
    analyzeText(this.props.boardName, id, updatedText);
  }

  deleteResult = () => {
    const { speech, boardName } = this.props;
    const { id } = speech;
    deleteDocument(boardName, id);
  }

  render() {
    const { admin, editing } = this.state;
    const { classes, speech } = this.props;

    const { text, keywords, translated, images, isTimestamp, createdAt, imageSearchWord } = speech;

    if (isTimestamp && createdAt) {
      return (
        <time style={{ margin: 16 }}>{createdAt.toDate().toLocaleString()}</time>
      );
    }

    let keywordsText = '';
    keywords && keywords.map((item, index) => {
      keywordsText += item.form;
      if (index !== keywords.length -1)
        keywordsText += '、';
      return item;
    });
    const keywordsResults = <Typography className={classes.pos}  component="p">{keywordsText}</Typography>

    const english = translated ? (
      <React.Fragment>
        <Typography className={classes.pos} color="textSecondary">
          In English
        </Typography>
        <Typography className={classes.pos}  component="p">
          {translated}
        </Typography>
      </React.Fragment>
    ) : null;

    const adminTextStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    }

    const textField = admin && editing ? (
      <div className="result text admin"  style={adminTextStyle}>
        <Input
          // defaultValue={text}
          value={this.state.updatedText}
          className={classes.input}
          inputProps={{
            'aria-label': 'Description',
          }}
          multiline
          fullWidth
          onChange={event => this.setState({ updatedText: event.target.value })}
        />
        <Button
          variant="contained"
          className={classes.button}
          onClick={this.updateResult}
          color="primary"
        >
          Update
        </Button>
    </div>
    ) : (
      <Typography className={classes.pos} component="p">
        {text}
      </Typography>
    )

    const image = images && images.length ? <img src={convertHttps(images[0].url)} alt={images[0].title} /> : null;

    const searchWord = imageSearchWord ? (
      <React.Fragment>
        <Typography className={classes.pos} color="textSecondary">
          {'The most important word(image search word)'}
        </Typography>
        <Keyword className={classes.pos} keyword={imageSearchWord} />
      </React.Fragment>
    ) : null;

    return (
      <Card className={classes.card}>
        <CardContent>
          {searchWord}
          {image}
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            keywords
          </Typography>
          {keywordsResults}
          <Typography className={classes.pos} color="textSecondary">
            Input Text
          </Typography>
          {textField}
          {english}
          <div className="result text admin"  style={adminTextStyle}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={this.deleteResult}
              color="secondary"
            >
              Delete
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(ResultCard);
