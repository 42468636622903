import React, { Component } from 'react';

import {
  updateBoardTitle,
} from '../../lib/firestore';
import { guestUserId, guestUserName, defaultBoardTitle } from '../../assets/constants';

class Title extends Component {
  constructor(props) {
    const editingTitle = props.title || '';
    super(props);
    this.state = {
      editing: false,
      editingTitle,
    };
  }

  componentDidUpdate(prevState) {
    const { title } = this.props;
    const { editing, editingTitle } = this.state;
    if (!editing && title !== editingTitle) {
      this.setState({ editingTitle: title });
    }

    if (editing && !prevState.editing) {
      this.inputTitle && this.inputTitle.focus();
    }
  }

  toggleEditing= () => this.setState({ editing: !this.state.editing });

  handleTitle = event => this.setState({ editingTitle: event.target.value });

  handleCancel = event => {
    event && event.preventDefault();
    event && event.stopPropagation();
    this.setState({ editingTitle: this.props.title, editing: false });
  }

  handleSave = event => {
    event && event.preventDefault();
    event && event.stopPropagation();
    const { editingTitle } = this.state;
    const { title, boardId } = this.props;
    if (editingTitle.trim() !== title) {
      updateBoardTitle(boardId, editingTitle.trim());
    }
    this.setState({ editing: false});
  }

  render() {
    const { editing, editingTitle } = this.state;
    const { title, presenter, admin } = this.props;
    const name = presenter && presenter.id !== guestUserId ? presenter.name : guestUserName;

    const display = (
      <React.Fragment>
        <div className="title-display">
          <h1 className="title has-text-white is-inline is-size-5-mobile">
            {typeof title === 'undefined' ? '' : title || defaultBoardTitle}
          </h1>
          {admin && ( // eslint-disable-next-line
            <a
              className="button button-text-edit is-success is-small is-inverted is-rounded"
              style={{marginLeft: .5 + 'rem'}}
              title="タイトル変更"
              onClick={this.toggleEditing}
            >
              <span className="icon"><i className="fas fa-pen" /></span>
            </a>
          )}
        </div>
      </React.Fragment>
    )

    const editForm = (
      <div className="title-edit">

          <div className="control">
            <input
              ref={node => this.inputTitle = node}
              className="input is-success"
              type="text"
              placeholder="タイトル"
              value={editingTitle}
              onChange={this.handleTitle}
            />
          </div>
          <nav className="nav-text-edit level is-mobile" style={{marginTop: .75 + 'rem'}}>
            <div className="level-right">
              <button
                type="button"
                title="キャンセル"
                className="button is-text button-edit level-item"
                onClick={this.handleCancel}
                ><span className="icon"><i className="fas fa-times" /></span><span>キャンセル</span></button>
              <button
                type="button"
                title="保存"
                className="button button-edit is-success level-item"
                onClick={this.handleSave}
              ><span className="icon"><i className="fas fa-check" /></span><span>保存</span></button>
            </div>
          </nav>

      </div>
    )

    return (
      <section className="hero board-title">
        <div className="hero-body">
          <header>
            <form className="field" onSubmit={this.handleSave} >
              {editing ? editForm : display}
            </form>
            <h2 className="subtitle has-text-white is-size-6-mobile">
              <span className="is-size-7">プレゼンター　</span>
              {typeof presenter === 'undefined' ? '' : name || ''}
            </h2>
          </header>
        </div>
      </section>
    );
  }
}

export default Title;
