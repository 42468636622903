import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
// import { firebaseConfig } from './assets/constants';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import './Admin.scss';
import { service } from '../../lib/asr';
import {
  startLive,
  finishLive,
  addTimestampStart,
  // addTimestampEnd,
  addDocument,
  updateDocument,
  deleteAll,
} from '../../lib/firestore';
import { analyzeText } from '../../lib/app';
import Input from '../Input/Input';
import RecordButton from '../RecordButton/RecordButton';
import DeleteButton from '../DeleteButton/DeleteButton';
import Result from '../Result/Result';
import TextAlert from '../TextAlert/TextAlert';
import Circular from '../Circular/Circular';

// firebase.initializeApp(firebaseConfig);

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    minHeight: 36,
  },
  inputContianer: {
    display: 'flex',
    columnDirection: 'row',
    alignItems: 'baseline',
  }
});

class Admin extends Component {
  state = {
    text: '',
    speeches: [],
    listening: false,
    alert: false,
    loading: false,
    currentSpeech: null,
    maxTextLength: 50,
  }

  componentDidMount() {
    this.unsubscribe =  this.subscribeDB();
    window.onbeforeunload = this.handleUnload;
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleUnload = () => {
    if (this.state.listening) {
      finishLive(this.props.boardName);
    }
  }

  subscribeDB = () => {
    const db = firebase.firestore();
    const subDir = this.props.boardName;
    const path = `speeches/${subDir}/items`;
    return db.collection(path).orderBy('createdAt', 'desc')
      .onSnapshot(querySnapshot => {
        const speeches = [];
        querySnapshot.forEach(doc => {
          const { id } = doc;
          const object = { ...doc.data(), id }
          speeches.push(object);
        });
        this.setState({ speeches });
      });
  }

  handleSpeech = speech => {
    const text = speech.replace(/\s+/g, '');

    const { currentSpeech } = this.state;
    if (currentSpeech) {
      const currentSpeechId = currentSpeech.id;
      const currentSpeechText = currentSpeech.text;
      const sumText = currentSpeechText + '　' + text;
      console.log(sumText);
      const doc = { text: sumText };
      updateDocument(this.props.boardName, currentSpeechId, doc).then(() => this.handleDocument(currentSpeechId, sumText));
    } else {
      addDocument(this.props.boardName, text).then(id => this.handleDocument(id, text));
    }
  }

  handleDocument = (id, text) => {
    // analyzeText(id, text); //50文字を超えないと解析しない
    let currentSpeech = { id, text };
    if (text.length > this.state.maxTextLength) {
      currentSpeech = null;
      analyzeText(this.props.boardName, id, text); //50文字を超えないと解析しない
    }
    this.setState({ currentSpeech });
  }

  resetState = () => this.setState({ text: '' });

  handleText = event => this.setState({ text: event.target.value })

  handleButton = () => {
    const { text } = this.state;
    if (text) {
      this.handleSpeech(text);
      this.resetState();
    }
  }

  handleBreak = () => {
    if (this.state.currentSpeech) {
      const { id, text } = this.state.currentSpeech;
      analyzeText(this.props.boardName, id, text);
      this.setState({ currentSpeech: null });
    }
  }

  handleDelete = () => this.setState({ alert: true });

  toggleLoading = () => this.setState({ loading: !this.state.loading });

  handleOk = () => {
    this.toggleLoading();
    deleteAll(this.props.boardName, this.toggleLoading);
    this.setState({ alert: false });
  }

  handleCancel = () => this.setState({ alert: false });

  toggleRecord = () => {
    const { listening } = this.state;

    if (!listening) {
      startLive(this.props.boardName); // デモ環境が終わり複数クライアントからの配信の可能性がある状態になったらlive状態をID識別して保存する必要がある
      addTimestampStart();
      this.asr = service(this.handleSpeech, this.handleFallback);
      this.asr.start(true);
    } else {
      finishLive(this.props.boardName);
      // addTimestampEnd();
      this.asr && this.asr.stop();
      this.asr = null;
    }
    if (this.state.currentSpeech) {
      const { id, text } = this.state.currentSpeech;
      analyzeText(id, text);
    }
    this.setState({ listening: !listening, currentSpeech: null });
  }

  handleFallback = () => {
    if (this.state.listening) {
      this.asr && this.asr.stop();
      this.asr = service(this.handleSpeech, this.handleFallback);
      this.asr.start(true);
    }
  }

  render() {
    const { classes, boardName } = this.props;
    const { speeches, text, listening } = this.state;

    const result = speeches.map((speech, index) => (
      <Result
        speech={speech}
        key={speech.id}
        boardName={boardName}
      />
    ))

    const textStyle = {
      color: 'grey',
      marginTop: 32,
    }

    const alertText = `アイテムを全て削除してよろしいですか？`

    return (
      <div className="App">
        <div className={classes.inputContianer} >
          <Input handleText={this.handleText} value={text} />
          <Button
            variant="contained"
            className={classes.button}
            onClick={this.handleButton}
          >
            Enter
          </Button>
        </div>
        <Button
          variant="contained"
          className={classes.button}
          onClick={this.handleBreak}
        >
          Break Section
        </Button>
        {result}
        <Typography color="textSecondary" style={textStyle} >
          *テキストに改行が入っていると翻訳が正常に作動しません。
        </Typography >
        <RecordButton onClick={this.toggleRecord} active={listening} />
        <DeleteButton onClick={this.handleDelete} />
        <TextAlert
          open={this.state.alert}
          onClose={this.handleCancel}
          onOk={this.handleOk}
          text={alertText}
          cancel
          onCancel={this.handleCancel}
        />
        <Circular
          show={this.state.loading}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Admin);
