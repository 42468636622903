import React, { Component } from 'react';

import Comments from './Comments';
import CommentFormContainer from '../../containers/CommentFormContainer';
import ModalUrl from './ModalUrl';
import cotoha from '../../assets/images/cotoha.svg';

class Drawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showImageSetting: false,
      showLangSetting: false,
    };
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  handleClick = event =>{
    if (this.imageSetting && !this.imageSetting.contains(event.target)) {
      this.toggleImageSetting(false);
    }
    if (this.langSetting && !this.langSetting.contains(event.target)) {
      this.toggleLangSetting(false);
    }
  }

  toggleImageSetting = (showImageSetting = !this.state.showImageSetting) => {
    if (showImageSetting !== this.state.showImageSetting) {
      this.setState({ showImageSetting })
    }
  }

  toggleLangSetting = (showLangSetting = !this.state.showLangSetting) => {
    if (showLangSetting !== this.state.showLangSetting) {
      this.setState({ showLangSetting })
    }
  }

  render() {
    const { showLangSetting, showImageSetting } = this.state;
    const {
      boardId,
      admin,
      showComments,
      toggleComments,
      recording,
      toggleRecord,
      clipped,
      toggleClip,
      handleImagesNumber,
      maxiumImages,
      toggleJapanese,
      toggleEnglish,
      japanese,
      english,
      toggleModalUrl,
      modalUrl,
    } = this.props;

    return (
      <div className="board-side">
        <Comments boardId={boardId} />
        <div className="is-mobile-bottom-nav">
          <div className="cotoha-area">
            <div className="cotoha-text">
              Powered by
            </div>
            <img src={cotoha} alt="Powered by COTOHA" className="cotoha-logo" />
          </div>
          <CommentFormContainer boardId={boardId} />

          <nav className="nav-vertical menu">
              <ul className="menu-list has-text-centered is-flex-touch is-block">
                {admin && (
                  <li>
                    {
                      // eslint-disable-next-line
                      <a title="音声入力" className={`button-record${ recording ? ' active' : ''}`} onClick={toggleRecord}>
                          <span className="icon-has-bg">
                            <i className="fas fa-microphone"></i>
                          </span>
                          <p className="nav-status is-size-7"></p>
                      </a>
                    }
                  </li>
                )}
                <li>
                  <div title="ボードのURL表示" className="dropdown-trigger" >
                    {
                      // eslint-disable-next-line
                      <a
                        onClick={toggleModalUrl}
                      >
                        <span className="icon-has-bg"><i className="fas fa-link"></i></span>
                        <p className="nav-status is-size-7">URL</p>
                      </a>
                    }
                  </div>
                  <ModalUrl active={modalUrl} toggleModal={toggleModalUrl} boardId={boardId} />

                </li>
                <li>
                  <div
                    ref={node => this.imageSetting = node}
                    className={`dropdown is-up is-block${ showImageSetting ? ' is-active' : ''}`}
                    onClick={() => this.toggleImageSetting(!showImageSetting)}
                  >
                      <div title="画像表示数" className="dropdown-trigger" >
                        {
                          // eslint-disable-next-line
                          <a aria-haspopup="true" aria-controls="dropdown-images" className="button-images">
                            <span className="icon-has-bg"><i className="fas fa-images"></i></span>
                            <p className="nav-status is-size-7" data-images={maxiumImages}></p>
                          </a>
                        }
                      </div>
                      <div className="dropdown-menu" id="dropdown-images" role="menu">
                        <div className="dropdown-content">
                            <h6 className="is-size-7">画像の表示枚数</h6>
                            <hr className="dropdown-divider"/>
                            <div className="buttons has-addons" style={{ flexWrap: 'nowrap' }}>
                              {
                                // eslint-disable-next-line
                                <a className="button" onClick={() => handleImagesNumber(1)} >1</a>
                              }
                              {
                                // eslint-disable-next-line
                                <a className="button" onClick={() => handleImagesNumber(2)} >2</a>
                              }
                              {
                                // eslint-disable-next-line
                                <a className="button" onClick={() => handleImagesNumber(3)} >3</a>
                              }
                              {
                                // eslint-disable-next-line
                                <a className="button" onClick={() => handleImagesNumber(4)} >4</a>
                              }
                              {
                                // eslint-disable-next-line
                                <a className="button" onClick={() => handleImagesNumber(5)} >5</a>
                              }
                              {
                                // eslint-disable-next-line
                                <a className="button" onClick={() => handleImagesNumber(6)} >6</a>
                              }
                            </div>
                        </div>
                      </div>
                  </div>
                </li>
                <li>
                  <div
                    ref={node => this.langSetting = node}
                    className={`dropdown is-up is-block${ showLangSetting ? ' is-active' : ''}`}
                    onClick={() => this.toggleLangSetting(!showLangSetting)}
                  >
                      <div title="言語表示" className="dropdown-trigger" >
                        {
                          // eslint-disable-next-line
                          <a aria-haspopup="true" aria-controls="dropdown-lang">
                            <span className="icon-has-bg"><i className="fas fa-globe"></i></span>
                            <p className="nav-status is-size-7">LANG</p>
                          </a>
                        }
                      </div>
                      <div className="dropdown-menu" id="dropdown-lang" role="menu">
                        <div className="dropdown-content has-text-left">
                            <h6 className="is-size-7 has-text-centered">表示言語</h6>
                            <hr className="dropdown-divider"/>
                            <div className="columns dropdown-item">
                                <label className="column checkbox" >
                                    <input type="checkbox" checked={english} onChange={toggleEnglish} />EN
                                </label>
                                <label className="column checkbox" >
                                    <input type="checkbox" checked={japanese} onChange={toggleJapanese}/>JP
                                </label>
                            </div>
                        </div>
                      </div>
                  </div>
                </li>
                <li>
                  {
                    // eslint-disable-next-line
                    <a
                      className={`button-comment${ showComments ? ' active' : ''}`}
                      title="コメント表示/非表示"
                      onClick={toggleComments}
                    >
                        <span className="icon-has-bg">
                            <i className="fas fa-comment-dots"></i>
                        </span>
                        <p className="nav-status is-size-7 "></p>
                    </a>
                  }
                </li>
                <li>
                  {
                    // eslint-disable-next-line
                    <a
                      className={`button-clip${ clipped ? ' active' : ''}`}
                      title="ピンする"
                      onClick={toggleClip}
                    >
                        <span className="icon-has-bg">
                            <i className="fas fa-thumbtack"></i>
                        </span>
                        <p className="nav-status is-size-7 "></p>
                    </a>
                  }
                </li>
              </ul>
          </nav>
        </div>
      </div>

    );
  }
}

export default Drawer;
