import { asrCredentials } from '../assets/constants';
import { enqueue } from './queue';

export function service(audioContext, callback, fallback) {
  const model = 'ja-gen_tf-16';
  const baseUrl = 'https://api.ce-cotoha.com/api/';
  const asrUrl = baseUrl + "asr/v1/speech_recognition/" + model;
  const authUrl = "https://api.ce-cotoha.com/v1/oauth/accesstokens";
  const domainId = 'qvMJVEMY';

  let recording = false;
  // let scope;
  // let speechConfig= null;
  // let configOpen = true;
  // let parameter = {};
  // let stream;

  let isStopBy = false;

  let acctoken='';
  let acctokenExpireTime;
  let uid='';

  let _audioSource = null;
  // let _context = new (window.AudioContext || window.webkitAudioContext)();
  let _context = audioContext;
  // console.log('context', _context);
  let _processor = null;
  // console.log('sampleRate', _context.sampleRate)
  const sampleRate = _context.sampleRate;
  const stopmsg = {"msg":{"msgname":"stop"}};
  // const cancelmsg ={"msg":{"msgname":"cancel"}};

  function onAudioProcess(e) {
    function task() {
      var floatBuffer =e.inputBuffer.getChannelData(0) || null;
      var bufferSize = floatBuffer.length;
      var shortBuffer = new Int16Array(bufferSize);
      for(var i=0; i<shortBuffer.length; i++) {
          shortBuffer[i] = Math.min(1,floatBuffer[i])*0x7FFF;
      }
      // 送る直前でSTOPされていないかチェック
      if(isStopBy===false){
          console.log('onAudioProcess')

          const headers = {
              'Content-Type': 'application/octet-stream',
              'Unique-ID': uid,
              'Authorization': acctoken,
          };
          return fetch(asrUrl, {
              method: 'POST',
              headers,
              credentials: 'include',
              body: shortBuffer
          })
          .then(res => res.text())
          .then(function (text) {
              try {
                  // 空白をparseするとエラーとなる
                  var jresp = JSON.parse(text || null);
                  if(jresp!==null){
                      for ( var i = 0; i < jresp.length; i++) {
                          console.log(i, jresp[i]);
                          if (jresp[i]['errorinfo']) {
                              console.error(jresp);
                              console.error(jresp[i]['errorinfo']['message']);
                              recording = false;
                              isStopBy = true;
                              fallback && fallback();
                          } else if (jresp[i]['msg']['msgname'] === 'recognized' && jresp[i]['result']['sentence'] !== []
                              && jresp[i]['result']['sentence'][0] !==undefined && jresp[i]['result']['sentence'][0]['surface']!==undefined) {
                              var payload = jresp[i]['result']['sentence'][0]['surface'];
                              // $rootScope.$broadcast('cotoharec',payload,false);
                              console.log('cotoharec', payload);
                              callback && callback(payload);
                          }
                      }
                  }
              }catch(e){
                 console.error(e);
              }
          })
          .catch(() => fallback && fallback());
      }
    }
    enqueue(task);
  };

  // 音声処理初期設定関数（下記getUserMediaのコールバックとして、マイクとの接続開始時に実行）
  function startUserMedia(stream) {
    console.log('startUserMedia')
      _processor = _context.createScriptProcessor(16384, 1, 1); // 0, 256, 512, 1024, 2048, 4096, 8192, 16384
      _audioSource = _context.createMediaStreamSource(stream);
      _audioSource.connect(_processor);
      _processor.onaudioprocess = onAudioProcess;
      // eslint-disable-next-line
      var node = _processor.connect(_context.destination);
  };

  var service = {

      // マイクスタート
      start : function(chekflg) {
          console.log('start');

          if (recording) {
              return;
          }

          isStopBy=false;
          // テスト送信の場合
          if(chekflg===true){
              requestSpeechRec();
          // 残り時間が3分をきったらアクセストークンを取得しなおす
          }else if(acctokenExpireTime === null || acctokenExpireTime - Date.now() < 180000){
              requestSpeechRec();
          }else{
              sendRecStartMessage();
          }
          // スタート
          function sendRecStartMessage(){
              console.log('sendRecStartMessage');
              var startmsg = {
                msg: { msgname: "start" },
                param: {
                  "baseParam.samplingRate": sampleRate,
                  "recognizeParameter.domainId": domainId,
                  "recognizeParameter.enableContinuous": true
                }
              };

              const headers = {
                  "Content-Type": "application/json; charset=utf-8",
                  "Authorization": acctoken,
              };
              fetch(asrUrl, {
                  method: "POST",
                  headers,
                  credentials: 'include',
                  body: JSON.stringify(startmsg),
              })
              .then(res => res.json())
              .then(function (data) {
                if (data[0]&&data[0].msg && data[0].msg.cause === 'ERROR') {
                  console.error('error', data[0].msg)
                  fallback && fallback();
                }

                if (data[0]&&data[0].msg&& data[0].msg.uniqueId) {
                  uid = data[0].msg.uniqueId;
                  console.log('msg', data[0].msg)
                }

                try {
                    // webkit shim
                    window.AudioContext = window.AudioContext || window.webkitAudioContext;
                    navigator.getUserMedia = ( navigator.getUserMedia ||
                        navigator.webkitGetUserMedia ||
                        navigator.mozGetUserMedia ||
                        navigator.msGetUserMedia);

                    if (typeof navigator.mediaDevices.getUserMedia === 'undefined') {
                        navigator.getUserMedia({audio: true}, startUserMedia,function (e) {});
                        console.log('getUserMedia', false)
                    } else {
                        navigator.mediaDevices.getUserMedia({audio: true}).then(startUserMedia)

                        console.log('getUserMedia', true)
                        // .catch(e);
                    }
                } catch (e) {
                    alert('No web audio support in this browser!');
                }

              }).catch(function(data) {
                  if(chekflg===true){
                    console.log('cotoharec','',true);
                    fallback && fallback();
                      // $rootScope.$broadcast('cotoharec','',true);
                  }
              });
          };
          // アクセストークンの取得
          function requestSpeechRec(){
              console.log('requestSpeechRec')
              // アクセストークンの取得
              var getToken = function() {
                  // const defer = $.Deferred();
                  var prm = {
                    grantType: "client_credentials",
                    ...asrCredentials,
                  };
                  fetch(authUrl, {
                      method: "POST",
                      headers: {'Content-Type': 'application/json; charset=UTF-8'},
                      body: JSON.stringify(prm),
                  })
                  .then(res => res.json())
                  .then(function (data) {
                      if(!data || !data.access_token){
                          // deferred.reject("");
                          console.log('error in access_token')
                      }else{
                        // 取得できたアクセストークンをセット
                        const token = data.access_token;
                        acctoken = "Bearer " + token;
                        var expdate = new Date(parseInt( token.issued_at));
                        acctokenExpireTime = expdate.setDate(expdate.getDate() + 1);
                        sendRecStartMessage();
                      }
                  }).catch(function(data) {
                      // テスト送信ならばエラーを表示する
                      if(chekflg===true){
                          // $rootScope.$broadcast('cotoharec','',true);
                      }
                      // defer.reject("");
                  });
                  // return defer.promise(e);
                  return;
              };
              getToken();
              // sendRecStartMessage(); // test

              // アクセストークン取得のレスポンス
              // $.when(getToken())
              //     .then(function(token) {
              //         // 取得できたアクセストークンをセット
              //         acctoken = "Bearer " + token.access_token;
              //         var expdate = new Date(parseInt( token.issued_at));
              //         acctokenExpireTime = expdate.setDate(expdate.getDate() + 1);
              //         sendRecStartMessage();
              //     //initRecognizer();
              // });
          };
  },

  stop : function(callbackStop) {
      try {
          // $interval.cancel(sendAodioBuffertimer);
          if(_processor){
              _processor.disconnect();
              _processor.onaudioprocess = null;
          }
          isStopBy = true;
          const headers = {
              'Content-Type': 'application/json;charset=UTF-8',
              'Unique-ID': uid,
              'Authorization': acctoken
          };
          fetch(asrUrl, {
              method: 'POST',
              headers,
              credentials: 'include',
              body: JSON.stringify(stopmsg)
          }).then(function (response) {
              return response.text();
          }).then(function (text) {
              try {
                  // 空白をparseするとエラーとなる
                  var jresp = JSON.parse(text || null);
                  if(jresp!==null){
                      for ( var i = 0; i < jresp.length; i++) {
                          // $rootScope.$broadcast  第三引数 エラーの場合はtrue
                          if(jresp[i]['errorinfo']){
                              console.error(jresp[0]['errorinfo']['message']);
                              // recording= false
                          }else if(jresp[i]['msg']['msgname'] === 'recognized' && jresp[i]['result']['sentence'] !== []
                              && jresp[i]['result']['sentence'][0] !==undefined && jresp[i]['result']['sentence'][0]['surface']!==undefined){
                              var payload = jresp[i]['result']['sentence'][0]['surface'];
                              // $rootScope.$broadcast('cotoharec',payload,false);
                              console.log('cotoharec',payload,false);
                          }
                      }
                  }

                callbackStop && callbackStop();
              }catch(e){
                  console.error(e);
              }
              isStopBy=true;
              recording=false;
          });
          // if(scope !== undefined){
          //     scope.surface="";
          //     //setTimeout(function(){scope.$apply();});
          // }
      }
      catch (e) {
          isStopBy=true;
          recording=false;
      }
  },

 // 現在未使用
  // cancel : function() {
  //     try {
  //          $http({
  //              url: applicationSettings.speechRecURL['COTOHA'] + $localStorage.speechRecModel,
  //              method: 'POST',
  //              withCredentials: true,
  //              headers: {"Content-Type": "application/json;charset=UTF-8","Unique-ID":uid,"Authorization": acctoken},
  //              data:JSON.stringify(cancelmsg)
  //          }).success(function (data,status,headers,config) {
  //              isStopBy=true;
  //              scope.apikeySuccess='IDS_SETTINGS_SETTING_AZURE_OK';
  //              if(_processor){
  //                  _processor.disconnect();
  //                  _processor.onaudioprocess = null;
  //              }
  //          }).error(function(data,status,headers,config) {
  //              throw "cancel err";
  //          });
  //
  //         /*
  //         var cancelJson = {msg:{msgname:"cancel"}};
  //         _websocketWorker.postMessage({cmd:'send', data:JSON.stringify(cancelJson)});
  //         showInfo('Send', JSON.stringify(cancelJson));
  //         */
  //     }
  //     catch (e) {
  //         showError('cancel', e.message);
  //     }
  // }
  };
  return service;
}
