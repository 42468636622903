import { connect } from 'react-redux';

// import * as app from '../modules/app';
import Dashboard from '../components/Dashboard/Dashboard';

const mapStateToProps = state => {
  const {
    loginUser,
  } = state.app;

  return {
    loginUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
