import React, { Component } from 'react';
import { withRouter } from 'react-router';

import DeleteDialog from './DeleteDialog';
import {
  fetchUser,
  fetchThumbnail,
  deleteBoard,
  clipBoard,
} from '../../lib/firestore';
import { guestUser, guestUserId } from '../../assets/constants';
import defaultThumnail from '../../assets/images/thum_board_noimg.svg';
import defaultUserIcon from '../../assets/images/thum_user.svg';
import defaultGuestIcon from '../../assets/images/thum_user_guest.svg';

class BoardCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      thumbnail: null,
      deleted: false,
      showDeleteDialog: false,
      fetchingThumbnail: true,
    };
  }

  componentDidMount() {
    this.fetchUser();
    this.fetchThumbnail();
  }

  fetchUser = () => {
    const userId = this.props.board.createdBy;
    if (userId !== guestUserId) {
      fetchUser(userId).then(user => this.setState({ user }));
    } else {
      this.setState({ user: guestUser });
    }
  }

  fetchThumbnail = async () => {
    const { board } = this.props;
    const image = await fetchThumbnail(board.id)
    const object = { fetchingThumbnail: false }
    if (image) {
      object.thumbnail = image.url;
    }
    this.setState(object)
  }

  toggleDeleteDialog = (showDeleteDialog = !this.state.showDeleteDialog) => {
    if (showDeleteDialog !== this.state.showDeleteDialog) {
      this.setState({ showDeleteDialog });
    }
  }

  openBoard = () => {
    const { id, urlAlias } = this.props.board;
    const path = urlAlias ? urlAlias : id;
    this.props.history.push({ pathname: `${process.env.PUBLIC_URL}/alpha/board/${path}` });
  }

  handleDelete = async event => {
    event && event.preventDefault();
    event && event.stopPropagation();
    this.toggleDeleteDialog(true);
  }

  deleteBoard = async event => {
    const { board, onDelete } = this.props;
    await deleteBoard(board.id)
    this.setState({ deleted: true }, onDelete);
  }

  handleClip = async event => {
    event && event.preventDefault();
    event && event.stopPropagation();
    const { board, loginUser, onClip } = this.props;
    if (!loginUser) {
      return;
    }
    await clipBoard(board.id, loginUser.id)
    onClip && onClip();
  }

  render() {
    const { user, deleted, showDeleteDialog, fetchingThumbnail } = this.state;
    if (deleted) {
      return null;
    }

    const { board, admin, loginUser } = this.props;
    const { createdAt } = board;

    const title = board.title || '無題のボード';
    const name = user ? user.name : '';
    const icon = user && user.id !== guestUserId ? user.icon || defaultUserIcon : defaultGuestIcon;
    const thumbnail = this.state.thumbnail || defaultThumnail;

    return (
      <div className="column is-half-touch is-one-quarter-desktop">
        <div className="card card-board clickable" onClick={this.openBoard}>
            <div className="card-image">
              <figure className="image is-2by1">
                {!fetchingThumbnail && <img src={thumbnail} alt="thumbnail" />}
              </figure>
            </div>
            <div className="card-content">
              <h3 className="title is-6 is-size-7-mobile">{title}</h3>
              <p className="is-size-7">
                <time dateTime="2016-1-1">{createdAt && createdAt.toDate().toLocaleString()}</time>
              </p>

              {admin && (
                <nav className="card-nav level" onClick={this.handleDelete} >
                  <div className="level-right">
                    {false && admin && (
                      // eslint-disable-next-line
                      <a title="ボードの編集" className="level-item">
                        <span className="icon is-size-6-mobile is-size-5-tablet"><i className="fas fa-pen" /></span>
                      </a>
                    )}
                    {false && loginUser && (
                      // eslint-disable-next-line
                      <a title="ボードのピン" className="level-item" onClick={this.handleClip} >
                        <span className="icon is-size-6-mobile is-size-5-tablet is-active has-text-white" ><i className="fas fa-thumbtack" /></span>
                      </a>
                    )}
                    {admin && (
                      // eslint-disable-next-line
                      <a title="ボードの削除" className="level-item">
                        <span className="icon is-size-6-mobile is-size-5-tablet"><i className="fas fa-trash-alt" /></span>
                      </a>
                    )}
                  </div>
                </nav>
              )}
            </div>
            <div className="card-footer">
              <div className="card-footer-item media">
                <div className="media-left is-hidden-mobile">
                  <figure className="image icon-prof is-24x24">
                    <img className="image is-24x24 is-rounded" src={icon} alt="thumbnail" />
                  </figure>
                </div>
                <div className="media-content">
                  <p className="is-5 is-size-7-mobile">{name}</p>
                </div>
              </div>
            </div>
        </div>
        <DeleteDialog
          open={showDeleteDialog}
          handleDelete={this.deleteBoard}
          handleClose={() => this.toggleDeleteDialog(false)}
          boardTitle={title}
        />
      </div>
    );
  }
}

export default withRouter(BoardCard);
