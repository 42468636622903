import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import ReactGA from 'react-ga';
import * as firebase from 'firebase/app';
import 'firebase/auth';

import TopContainer from '../containers/TopContainer';
import DashboardContainer from '../containers/DashboardContainer';
import SettingContainer from '../containers/SettingContainer';
import Admin from './Admin/Admin';
import GuestPage from './GuestPage/GuestPage';
import BoardContainer from '../containers/BoardContainer';
import Terms from './Header/Terms';
import ModalContainer from '../containers/ModalContainer';

ReactGA.initialize('UA-134848184-1');

class App extends Component {

  componentWillMount() {
    this.unsubscribeAuth = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        this.props.setLoginUser(undefined);
        const {
          // displayName,
          // email,
          // emailVerified,
          // photoURL,
          // isAnonymous,
          uid,
          // providerData,
        } = user;

        this.unsubscribeUser = this.fetchUser(uid);

      } else {
        this.props.setGuestUser();
        if(this.unsubscribeUser) {
          this.unsubscribeUser();
        }
      }
    });
  }

  componentWillUnmount() {
    if (this.unsubscribeUser) {
      this.unsubscribeUser();
      this.unsubscribeUser = null;
    }
    this.unsubscribeAuth();
  }

  fetchUser = uid => {
    const db = firebase.firestore();
    return db.collection('users').doc(uid)
      .onSnapshot(doc => {
        if (!doc.data()) {
          return;
        }
        const user = {...doc.data(), id: doc.id}
        this.props.setLoginUser(user);
      })
  }

  openDashboard = () => this.props.history.push({ pathname: `${process.env.PUBLIC_URL}/dashboard` })

  render() {
    const top = () => <TopContainer />;
    const setting = () => <SettingContainer />;
    const dashboard = () => <DashboardContainer />;
    const terms = () => <Terms />

    const admin = () => <Admin boardName="test" />;
    const guest = () => <GuestPage boardName="test" />;
    const adminProd = () => <Admin boardName="prod" />;
    const guestProd = () => <GuestPage boardName="prod" />;
    const adminDevNTT = () => <Admin boardName="devNTT" />;
    const guestDevNTT = () => <GuestPage boardName="devNTT" />;
    const adminDevKonel = () => <Admin boardName="devKonel" />;
    const guestDevKonel = () => <GuestPage boardName="devKonel" />;
    const board = ({ match }) => <BoardContainer boardId={match.params.boardId} />;
    const redirect = () => <Redirect to="/" />;

    const tracker = () => {
      if (window.location.hostname === 'localhost') {
        return null;
      }
      ReactGA.set({ page: window.location.pathname });
      ReactGA.pageview(window.location.pathname);
      return null;
    };

    return (
      <Router
        // basename={'/base'}
      >
        <React.Fragment>
          <Route path="/" component={tracker} />
          <Switch>
            <Route path={`${process.env.PUBLIC_URL}/`} exact component={top} />

            <Route path={`${process.env.PUBLIC_URL}/dashboard`} exact component={dashboard} />
            <Route path={`${process.env.PUBLIC_URL}/dashboard/setting`} exact component={setting} />
            <Route path={`${process.env.PUBLIC_URL}/Terms`} exact component={terms} />

            <Route path={`${process.env.PUBLIC_URL}/alpha/board/:boardId`} exact component={board} />

            {
              // <Route path={`${process.env.PUBLIC_URL}/demo/board/:boardId`} exact component={board} />
            }

            <Route path={`${process.env.PUBLIC_URL}/board/test`} exact component={guest} />
            <Route path={`${process.env.PUBLIC_URL}/board/wakuwaku190220`} exact component={guestProd} />
            <Route path={`${process.env.PUBLIC_URL}/board/test/admin`} exact component={admin} />
            <Route path={`${process.env.PUBLIC_URL}/board/wakuwaku190220/admin`} exact component={adminProd} />
            <Route path={`${process.env.PUBLIC_URL}/board/dev-ntt`} exact component={guestDevNTT} />
            <Route path={`${process.env.PUBLIC_URL}/board/demo`} exact component={guestDevKonel} />
            <Route path={`${process.env.PUBLIC_URL}/board/dev-ntt/admin`} exact component={adminDevNTT} />
            <Route path={`${process.env.PUBLIC_URL}/board/demo/admin`} exact component={adminDevKonel} />

            <Route exact component={redirect} />
          </Switch>
          <ModalContainer />
        </React.Fragment>
      </Router>
    );
  }
}

export default App;
