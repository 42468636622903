import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { withRouter } from 'react-router';

import HeaderContainer from '../../containers/HeaderContainer';
import Title from './Title';
import Paragraph from './Paragraph';
import Drawer from './Drawer';
import TextAlert from '../TextAlert/TextAlert';
import Warning from '../Warning/Warning';
import { addHeightResizeListener, removeHeightResizeListener } from '../../lib/scroll';
import { service } from '../../lib/asr';
import {
  viewBoard,
  clipBoard,
  unclipBoard,
  setLive,
  createTimestampStart,
  createParagraph,
  updateParagraph,
  fetchUser,
  collectionParagraph,
  collectionBoard,
  getBoardIdByAlias,
} from '../../lib/firestore';
import { guestUserId } from '../../assets/constants';
import { analyzeSpeech } from '../../lib/app';
import cotoha from '../../assets/images/cotoha.svg';

class Board extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speeches: [],
      japanese: true,
      english: true,
      live: undefined,
      recording: false,
      start: -1,
      end: 30,
      limit: 60,
      alertText: 'プレゼンテーションが開始されました。自動スクロールに切り替えます。',
      alertTextBrowser: '音声認識機能はPC版Google ChromeとiOS Safariにのみ対応しています。',
      alert: false,
      alertBrowser: false,
      currentSpeech: null,
      boardId: undefined,
      maxTextLength: 50,
      warning: false,
      showComments: true,
      presenter: undefined,
      title: undefined,
      clippedUser: undefined,
      maxiumImages: 1,
      modalUrl: false,
    };
  }

  render() {
    const {
      boardId,
      speeches,
      japanese,
      english,
      start,
      end,
      live,
      recording,
      warning,
      showComments,
      title,
      presenter,
      clippedUser,
      maxiumImages,
      modalUrl,
    } = this.state;
    const { loginUser } = this.props;
    const admin = (presenter && presenter.id === guestUserId) || (loginUser && presenter && loginUser.id === presenter.id);
    const clipped = loginUser && clippedUser && clippedUser[loginUser.id];

    const paragraphs = speeches.map((speech, index) => {
      const virtualIndex = live ? speeches.length - index - 1 : index;
      const doubleStamp = index && speeches[index - 1].isTimestamp && speech.isTimestamp;

      return (
        // reactコンポネント以外でかこまないとスクロール先に指定できない
        <section
          key={speech.id}
          className="paragraph"
          ref={node => {
            if (index === speeches.length - 1) {
              this.lastItem = node;
            }
          }}
        >
          <Paragraph
            paragraph={speech}
            index={speech.index}
            japanese={japanese}
            english={english}
            show={!doubleStamp && (start < virtualIndex && virtualIndex < end)}
            admin={admin}
            maxiumImages={maxiumImages}
          />
      </section>
      );
    })

    return (
      <div
        className="page-board"
      >
        <div className="board-container is-flex-desktop">
          <div
            ref={node => this.boardMain = node}
            onScroll={this.onScroll}
            className={`board-main${ showComments ? '' : ' expand' }`}
          >
            <HeaderContainer dark stayAfterLogin />
            <main className="section" ref={node => this.container = node}  >
              <Title
                boardId={boardId}
                title={title}
                presenter={presenter}
                admin={admin}
              />
              <article >
                {paragraphs}
              </article>
            </main>
            <div className="cotoha-area">
              <div className="cotoha-text">
                Powered by
              </div>
              <img src={cotoha} alt="Powered by COTOHA" className="cotoha-logo" />
            </div>
          </div>
          <Drawer
            admin={admin}
            boardId={boardId}
            toggleComments={this.toggleComments}
            showComments={showComments}
            toggleRecord={this.toggleRecord}
            recording={recording}
            clipped={clipped}
            toggleClip={this.toggleClip}
            maxiumImages={maxiumImages}
            handleImagesNumber={this.changeMaxiumImages}
            toggleJapanese={this.toggleJapanese}
            toggleEnglish={this.toggleEnglish}
            japanese={japanese}
            english={english}
            modalUrl={modalUrl}
            toggleModalUrl={this.toggleModalUrl}
          />
        </div>
        <Warning open={warning} handleClose={this.closeWarnig} />
        <TextAlert
          open={this.state.alert}
          onClose={this.handleOk}
          onOk={this.handleOk}
          text={this.state.alertText}
          okButtonTitle="OK"
        />
        <TextAlert
          open={this.state.alertBrowser}
          onClose={this.toggleBrowserAlert}
          text={this.state.alertTextBrowser}
          onOk={this.toggleBrowserAlert}
        />
      </div>
    );
  }

  async componentDidMount() {
    let { boardId } = this.props;
    const boardIdByAlias = boardId && await getBoardIdByAlias(boardId);
    boardId = boardIdByAlias ? boardIdByAlias : boardId;
    this.setState({ boardId });

    if (boardId) {
      this.subscribeDB(boardId);
    } else {
      this.openDashboard();
      return;
    }

    window.onbeforeunload = this.handleUnload

    const mobile = window.innerWidth < 767;
    if (mobile) {
      document.addEventListener('touchmove', this.onScroll, false);
    }
    addHeightResizeListener(this.container, this.stickBottom);

    const { loginUser } = this.props;
    if (loginUser && loginUser.id !== guestUserId) {
      viewBoard(boardId, loginUser.id).catch(this.openDashboard);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const prevUser = prevProps.loginUser;
    const logInPrev = prevUser && prevUser.id !== guestUserId;
    const { loginUser } = this.props;
    const logIn = loginUser && loginUser.id !== guestUserId;
    if (!logInPrev && logIn){
      const { boardId } = this.state;
      viewBoard(boardId, loginUser.id).catch(this.openDashboard);
    }

    const first = !prevState.speeches.length && this.state.speeches.length;
    if (this.state.live && first) {
      setTimeout(() => {
        const mobile = window.innerWidth < 767;
        if (mobile) {
          this.scrollBottom();
        } else {
          const { scrollHeight } = this.boardMain;
          this.boardMain.scrollTop = scrollHeight;
        }
      }, 200)
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeBoard) {
      this.unsubscribeBoard();
    }
    if (this.unsubscribeContents) {
      this.unsubscribeContents();
    }
    removeHeightResizeListener(this.container);

    const mobile = window.innerWidth < 500;
    if (mobile) {
      document.removeEventListener('touchmove', this.onScroll, false);
    }
  }

  // ライブ中に画面最下部にスクロールしている場合は画面最下部に固定
  stickBottom = () => {
    const mobile = window.innerWidth < 767;
    const scrollTop = !mobile ? this.boardMain.scrollTop : window.scrollY;
    const { scrollHeight, offsetHeight } = this.boardMain;
    const scrollBottom = !mobile ? scrollHeight - (scrollTop + offsetHeight) : scrollHeight - (scrollTop + window.outerHeight);
    if (this.state.live && scrollBottom < window.outerHeight) {
      setTimeout(this.scrollBottom, 100)
    }
  }

  onScroll = () => {
    const { live } = this.state;

    const { scrollHeight, offsetHeight } = this.boardMain;

    const mobile = window.innerWidth < 767;
    const scrollTop = !mobile ? this.boardMain.scrollTop : window.scrollY;
    const scrollBottom = !mobile ? scrollHeight - (scrollTop + offsetHeight) : scrollHeight - (scrollTop + window.outerHeight);

    const top = scrollTop < window.outerHeight;
    const bottom = scrollBottom < window.outerHeight;

    if (live) {
       if (top) {
         this.nextContents();
       } else if (bottom) {
         this.backContents()
       }
    } else {
      if (top) {
        this.backContents();
      } else if (bottom) {
        this.nextContents()
      }
    }
  }

  showAlert = () => this.setState({ alert: true });

  handleOk = () => {
    this.setLiveMode(true);
    this.setState({ alert: false });
  }

  handleClose = () => this.setState({ alert: false });

  nextContents = () => {
    const { speeches, limit } = this.state;
    let { start, end } = this.state;
    const contentsLength = end - start;
    if (end < speeches.length) {
      end += 10;
      if (contentsLength > limit) {
        start = end - limit
      }
      this.setState({ start, end });
    }
  }

  backContents = () => {
    const { limit } = this.state;
    let { start, end } = this.state;
    if (start > -1) {
      start -= 10;
      if (start < 10) {
        start = -1
      }
      end = start + limit

      this.setState({ start, end });
    }
  }

  setLiveMode = live => {
    if (live !== this.state.live) {
      this.setState({ live, start: -1, end: 30 }, () => {
        setTimeout(() => {
          if (!live) {
            return
          }
          const mobile = window.innerWidth < 767;
          if (mobile) {
            this.scrollBottom();
          } else {
            const { scrollHeight } = this.boardMain;
            this.boardMain.scrollTop = scrollHeight;
          }
        }, 200)
      });
    }
  }

  subscribeDB = boardId => {
    this.unsubscribeBoard = this.fetchBoard(boardId);
    this.unsubscribeContents = this.fetchContents(boardId);
  }

  fetchBoard = boardId => {
    const db = firebase.firestore();
    return db.collection(collectionBoard).doc(boardId)
      .onSnapshot(doc => {
        if (!doc.data()) {
          this.openDashboard();
          return;
        }
        const { title, createdBy, live, clipped } = doc.data();

        if (typeof this.state.live === 'undefined') {
          this.setLiveMode(live);
        }
        if (live && !this.state.live) {
          this.showAlert();
        }

        if (title !== this.state.title) {
          this.setState({ title });
        }

        this.fetchPresenter(createdBy);

        // ToDo: ↓ユーザー数が増えた場合に危険
        this.setState({ clippedUser: clipped});

      })
  }

  openDashboard = () => this.props.history.push({ pathname: `${process.env.PUBLIC_URL}/dashboard` })

  fetchPresenter = async userId => {
    if (typeof this.state.presenter !== 'undefined') {
      return;
    }
    if (userId === guestUserId) {
      this.setState({
        presenter: { id: guestUserId },
      })
    } else {
      const presenter = await fetchUser(userId);
      this.setState({ presenter });
    }
  }

  fetchContents = boardId => {
    const db = firebase.firestore();
    return db.collection(collectionParagraph).where('boardId', '==', boardId).orderBy('createdAt')
      .onSnapshot(querySnapshot => {
        const speeches = querySnapshot.docs.map((doc, index) => {
            const { id } = doc;
            const object = {
              ...doc.data(),
              id,
              index,
            }
            return object;
        });
        this.setState({ speeches });
      })
  }

  scrollBottom = () => {
    if (this.state.live && this.lastItem) {
      this.lastItem.scrollIntoView({ behavior: 'smooth', block: "end"});
    }
  };

  toggleJapanese = () => this.setState({ japanese: !this.state.japanese })

  toggleEnglish = () => this.setState({ english: !this.state.english })

  toggleModalUrl = () => this.setState({ modalUrl: !this.state.modalUrl })

  handleUnload = event => {
    if (this.state.recording) {
      const { boardId } = this.state;
      setLive(boardId, false);

      event.returnValue = '音声認識起動中です。本当にブラウザを閉じますか？';
      return '音声認識起動中です。本当にブラウザを閉じますか？';
    }
  }

  setAudioContext = async () => {
    if (!this.context || 0 === Object.keys(this.context).length) {
      // console.log('*****create new*******', this.context)
      this.context = new (window.AudioContext || window.webkitAudioContext)();
    }
    // console.log('setAudioContext', this.context)
    return this.context;
  }

  validateBrowser = () => {
    const agent = window.navigator.userAgent;

    const google = window.navigator.vendor.indexOf('Google') !== -1;
    const chrome = !!agent.match(/Chrome/i);
    const googleChrome = google && chrome;

    const iOS = !!agent.match(/iPad/i) || !!agent.match(/iPhone/i);
    const webkit = !!agent.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !agent.match(/CriOS/i);

    const valid = googleChrome || iOSSafari

    if (!valid) {
      this.toggleBrowserAlert();
    }
    return valid;
  }

  toggleBrowserAlert = () => this.setState({ alertBrowser: !this.state.alertBrowser });

  toggleRecord = () => {
    if (!this.validateBrowser()) {
      return;
    }

    const { recording, boardId, currentSpeech } = this.state;

    if (!recording) {
      setLive(boardId, true);
      const { loginUser } = this.props;
      createTimestampStart(boardId, loginUser.id);

      this.setAudioContext().then(context => {
        if (context) {
          this.asr = service(context, this.handleSpeech, this.handleFallback);
          this.asr.start(true);
        } else {
          setLive(boardId, false);
          alert('音声認識の上限数に到達しました。ページをリロードしてください。')
        }
      })

      this.setLiveMode(true);
    } else {
      setLive(boardId, false);
      this.asr && this.asr.stop();
      // this.asr = null;

      // 表示領域が変更されるためliveモードをオフにはしない
      // this.setState({ live: false });
    }
    if (currentSpeech) {
      const { id, text } = currentSpeech;
      analyzeSpeech(id, text);
    }
    this.setState({ recording: !recording, currentSpeech: null });
  }

  handleSpeech = speech => {
    //暫定対応で<sp>[]を消す
    const text = speech.replace(/<sp>|\[|\]/g, '').replace(/\s+/g, '').trim();
    if (!text) {
      return;
    }
    const { currentSpeech, boardId } = this.state;
    if (currentSpeech) {
      const currentSpeechId = currentSpeech.id;
      const currentSpeechText = currentSpeech.text;
      const sumText = currentSpeechText + '　' + text;
      //console.log(sumText.trim());
      const doc = { text: sumText.trim() };
      updateParagraph(currentSpeechId, doc).then(() => this.handleDocument(currentSpeechId, sumText));
    } else {
      const { loginUser } = this.props;
      createParagraph(boardId, loginUser.id, text).then(id => this.handleDocument(id, text));
    }
  }

  handleDocument = (id, text) => {
    let currentSpeech = { id, text };
    if (text.length > this.state.maxTextLength) {
      currentSpeech = null;
      analyzeSpeech(id, text); //50文字を超えないと解析しない
    }
    this.setState({ currentSpeech });
  }

  handleFallback = () => {
    if (this.state.recording) {
      const callback = () => {
        this.setAudioContext().then(context => {
          this.asr = service(context, this.handleSpeech, this.handleFallback);
          this.asr.start(true);
        });
        this.asr.start(true);
      }
      this.asr && this.asr.stop(callback);
      this.showWarning();
    }
  }

  toggleComments = () => this.setState({ showComments: !this.state.showComments });

  toggleClip = () => {
    const { loginUser, showModalRegister } = this.props;
    if (!loginUser || loginUser.id === guestUserId) {
      showModalRegister();
      return;
    }

    const { boardId, clippedUser } = this.state;
    const clipped =  clippedUser && clippedUser[loginUser.id];
    if(!clipped) {
      clipBoard(boardId, loginUser.id);
    } else {
      unclipBoard(boardId, loginUser.id);
    }
  }

  changeMaxiumImages = maxiumImages => this.setState({ maxiumImages });

  showWarning = () => this.setState({ warning: true });

  closeWarnig = () => this.setState({ warning: false });

}

export default withRouter(Board);
