import React, { Component } from 'react';

import {
  fetchUser,
} from '../../lib/firestore';
import { guestUser, guestUserId } from '../../assets/constants';
import defaultUserIcon from '../../assets/images/thum_user.svg';
import defaultGuestIcon from '../../assets/images/thum_user_guest.svg';

class CommentCard extends Component {
  constructor(props) {
    super(props);
    const emptyUser = {
      name: '',
      icon: defaultGuestIcon,
    }
    this.state = {
      user: emptyUser,
    };
  }

  componentDidMount() {
    this.fetchUser()
  }

  fetchUser = () => {
    const { comment } = this.props;
    const { userId } = comment;
    if (userId !== guestUserId) {
      fetchUser(userId).then(user => this.setState({ user }));
    } else {
      this.setState({ user: guestUser });
    }
  }

  render() {
    const { user } = this.state;
    const { comment } = this.props;
    const { userId, text, createdAt } = comment;
    const lines = text.split('\n');

    const name = userId !== guestUserId ? user && user.name : comment.guestName;
    const icon = userId !== guestUserId ? (user && user.icon) || defaultUserIcon : defaultGuestIcon;

    return (
      <article className="media">
          <div className="media-left">
            <figure className="image icon-prof is-32x32">
              <img className="image is-32x32 is-rounded" src={icon} alt="icon" />
            </figure>
          </div>
          <div className="media-content">
            <div className="content">
              <strong className="is-size-7">{name || ''}</strong>
              <p className="is-size-6 is-marginless">
                {lines.map((line, index) => {
                  if (!line && index < lines.length - 1 && !lines[index + 1]) {
                    return null;
                  }
                  return (
                    <React.Fragment key={index} >
                      {line}
                      <br />
                    </ React.Fragment>
                  )
                })}
              </p>
              <time className="is-size-7 has-text-grey is-hidden-touch is-block">{createdAt && createdAt.toDate().toLocaleString()}</time>
            </div>
          </div>
      </article>
    );
  }
}

export default CommentCard;
