export const addHeightResizeListener = (element, callback) => {
  if (!element) {
    return;
  }
  let lastHeight = element.clientHeight;
  const run = () => {
    const newHeight = element.clientHeight;
    if ( lastHeight !== newHeight ) {
      callback && callback();
    }
    lastHeight = newHeight;
    if ( element.onElementHeightChangeTimer ) {
      clearTimeout(element.onElementHeightChangeTimer);
    }
    element.onElementHeightChangeTimer = setTimeout(run, 200);
  };
  run();
};

export const removeHeightResizeListener = element => {
  if (element && element.onElementHeightChangeTimer)
    clearTimeout(element.onElementHeightChangeTimer);
};


export const addScrollHeightResizeListener = (element, callback) => {
  if (!element) {
    return;
  }
  let lastHeight = element.scrollHeight;
  const run = () => {
    const newHeight = element.scrollHeight;
    if ( lastHeight !== newHeight ) {
      callback && callback();
    }
    lastHeight = newHeight;
    if ( element.onElementScrollHeightChangeTimer ) {
      clearTimeout(element.onElementScrollHeightChangeTimer);
    }
    element.onElementScrollHeightChangeTimer = setTimeout(run, 200);
  };
  run();
};

export const removeScrollHeightResizeListener = element => {
  if (element && element.onElementScrollHeightChangeTimer)
    clearTimeout(element.onElementScrollHeightChangeTimer);
};
